import { useContext } from 'react'
import { BillingPlanInfoContext } from './context'

/**
 * Hook that can be used to fetch data from BillingPlanInfoContext
 * @returns Available data from BillingPlanInfoContext
 */
export const useBillingPlanInfo = () => {
  const context = useContext(BillingPlanInfoContext)
  return context
}
