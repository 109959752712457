/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Editor } from '@tinymce/tinymce-react'
import React, { useRef } from 'react'
import { uploadFile } from './uploadFile'

const toolbars = {
  full: {
    menubar: 'insert format tools table',
    plugins: 'colorpicker code image lists autoresize link paste',
    toolbar:
      'formatselect | fontsizeselect | image | bold italic link | \
       alignleft aligncenter alignright | \
       bullist numlist mediaembed | removeformat',
    menu: {
      format: {
        title: 'Format',
        items: 'underline strikethrough fontformats forecolor backcolor',
      },
    },
    content_style: 'p{margin:0;}',
    file_picker_types: 'image',
    file_picker_callback: (cb: (filUrl: string, obj: any) => unknown) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')

      input.onchange = async function () {
        // @ts-ignore:next-line
        const file = this.files[0] as File
        const fileUrl = await uploadFile(null, file)
        cb(fileUrl, { title: file.name })
      }

      input.click()
    },
    invalid_elements: 'h1',
    block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre',
  },
  inline: {
    plugins: 'colorpicker autoresize paste',
    toolbar: 'bold italic',
    forced_root_block: 'div',
    menubar: false,
    statusbar: false,
    content_style: 'p{margin:0;}',
    invalid_elements: 'h1',
    block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre',
  },
}

export default function WysiwygField({
  value,
  name = '',
  toolbar = 'full',
  onChange,
}: {
  value: string
  name?: string
  toolbar?: string
  onChange: (data: { name: string; value: string }) => void
}) {
  // Just to make TS happy. There are no types for tinymce@3.6.x
  interface Editor {
    getContent(): string
    setDirty(dirty: boolean): void
  }

  const editorRef = useRef<Editor | null>(null)

  const onBlur = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent()
      editorRef.current.setDirty(false)
      onChange({ name, value: content })
    }
  }
  return (
    <div className="field field--wysiwyg" data-testid={name}>
      <Editor
        initialValue={value}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onBlur={onBlur}
        tinymceScriptSrc="https://d2nce6johdc51d.cloudfront.net/tinymce/tinymce.min.js"
        init={toolbars[toolbar] || toolbars.full}
      />
    </div>
  )
}
