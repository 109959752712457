import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material'

/**
 * Error message component that is displayed when something fatal happens and the
 * app cannot render itself. Translations are not available yet on this level so
 * text should be hardcoded.
 */
export const ErrorMessage = (props: { title: string; details?: string }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}>
      <Grid item xs={3}>
        <Paper sx={{ p: 3, minWidth: { xs: 320, sm: 560 }, boxShadow: theme => theme.customShadows.card }}>
          <Stack gap={3}>
            <Typography variant="h3">{props.title}</Typography>
            {props.details && <Typography variant="body1">{props.details}</Typography>}
            <Box textAlign="center">
              <Button variant="contained" onClick={() => window.location.reload()}>
                Try again
              </Button>
            </Box>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
