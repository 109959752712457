import { UserData } from '@tm/types/common/app-api/auth'
import { useEffect } from 'react'
import { dataLayerPush } from '../../track'

/**
 * Generate global options for datalayer based on auth data
 * We need these for marketing on page load
 */
export function useAuthLoadEvent(userData: UserData) {
  useEffect(() => {
    if (!userData?.email) return
    dataLayerPush('USER_LOAD', {
      email: userData?.email || '',
      gclid: userData?.marketingSource?.gclid || '',
      fbclid: userData?.marketingSource?.fbclid || '',
      userId: userData?.contact_id || '',
    })
  }, [userData])
}
