import React, { Suspense } from "react";
const CodeMirror = React.lazy(() => import("./codeMirror"));
export default function CodeEditorField(props) {
  let { mode = "css", value, name, onChange } = props;
  let options = {
    mode,
    lineNumbers: true,
    lineWrapping: true,
    tabSize: 2
  };
  return /* @__PURE__ */ React.createElement("div", { className: "field-input-code-editor" }, /* @__PURE__ */ React.createElement(
    Suspense,
    {
      fallback: /* @__PURE__ */ React.createElement("div", { className: "loader" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-spin fa-spinner" }))
    },
    /* @__PURE__ */ React.createElement(
      CodeMirror,
      {
        value,
        options,
        onBeforeChange: (editor, data, value2) => onChange({ name, value: value2 })
      }
    )
  ));
}
