import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'simplebar/src/simplebar.css'

import { CssBaseline } from '@mui/material'
import { enUS, fiFI } from '@mui/material/locale'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { CollapseDrawerProvider } from '@tm/ui/contexts/CollapseDrawerContext'
import { GlobalStyles, getTheme } from '@trustmary/ui/theme'
import { ReactNode } from 'react'
import { useI18n } from './i18n'

type Props = {
  children: ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const { lang } = useI18n()
  const locale = lang === 'fi' ? fiFI : enUS

  const theme = getTheme(locale)

  return (
    <CollapseDrawerProvider>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <div></div>
        {children}
      </MUIThemeProvider>
    </CollapseDrawerProvider>
  )
}
