import React, { useState, useRef } from "react";
import { ChromePicker } from "react-color";
import tinycolor from "tinycolor2";
import "./colorPicker.sass";
export default function ColorPicker(props) {
  let [open, setOpen] = useState(false);
  let inputRef = useRef(null);
  let { t, defaultColor, value = "", name, onChange, inline = false } = props;
  let color = value || defaultColor;
  const onInputChange = (input) => {
    let { value: value2 } = input;
    if (value2 === color) return;
    let c = tinycolor(value2);
    if (!c.isValid()) return input.value = color;
    changeColor(c.toHexString());
  };
  const changeColor = (value2) => {
    onChange({ name, value: value2 });
    if (inputRef.current) inputRef.current.value = value2 || defaultColor;
  };
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--color" }, /* @__PURE__ */ React.createElement("div", { className: `form-field-color form-field-color--${inline ? "inline" : "default"}` }, /* @__PURE__ */ React.createElement("div", { className: "form-field-color-input" }, /* @__PURE__ */ React.createElement(
    "button",
    {
      style: { backgroundColor: value ? value : defaultColor },
      onClick: (e) => e.preventDefault() & setOpen(!open),
      className: "form-field-color-button"
    },
    /* @__PURE__ */ React.createElement("i", { className: "fa fa-eye-dropper" })
  ), inline && /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "text",
      defaultValue: color,
      ref: inputRef,
      onBlur: (e) => onInputChange(e.target),
      onKeyDown: (e) => {
        if (e.key !== "Enter") return;
        e.preventDefault();
        onInputChange(e.target);
      }
    }
  )), open ? /* @__PURE__ */ React.createElement("div", { className: "form-field-color-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "form-field-color-overlay", onClick: (e) => setOpen(false) }), /* @__PURE__ */ React.createElement("div", { className: "form-field-color-picker" }, /* @__PURE__ */ React.createElement(ChromePicker, { color, onChange: (e) => changeColor(e.hex), disableAlpha: true }))) : null, /* @__PURE__ */ React.createElement("div", { className: "form-field-color-action" }, defaultColor ? /* @__PURE__ */ React.createElement(
    "button",
    {
      className: "button-plain form-field-color-reset",
      onClick: (e) => e.preventDefault() & changeColor(void 0)
    },
    t("colorPicker.resetColor")
  ) : /* @__PURE__ */ React.createElement("button", { className: "button-plain form-field-color-reset", onClick: (e) => e.preventDefault() & changeColor("") }, t("colorPicker.removeColor")))));
}
