import { PlanCategory } from '@tm/types/api/plans'
import { OrganizationSubscriptionStateChange } from '@tm/types/event/sns'
import { useCallback, useEffect, useState } from 'react'
import {
  GetSubscriptionScheduleResponse,
  ScheduledChangesByCategory,
} from '../../../../../server/app-api/billing-v2/routes/getSubscriptionScheduleRoute'
import { UndoSubscriptionScheduleBody } from '../../../../../server/app-api/billing-v2/routes/undoSubscriptionCancelRoute'
import { useApi } from '../../api'
import { useWebsocketEvent } from '../../api/websocket/useWebsocketEvent'
import { useAuth } from '../../auth'

export function useSubscriptionSchedules() {
  const { loggedIn } = useAuth()
  const { appApi } = useApi()
  const [state, setState] = useState<'loading' | 'ready' | 'error'>()
  const [scheduledChangesByCategory, setScheduledChangesByCategory] = useState<ScheduledChangesByCategory>()
  const [cancellingAt, setCancellingAt] = useState<Date | null>(null)

  const loadSchedule = useCallback(
    async (signal?: AbortSignal) => {
      try {
        const response = await appApi.get<GetSubscriptionScheduleResponse>(
          '/billing-v2/subscription-schedule',
          undefined,
          undefined,
          signal
        )
        setScheduledChangesByCategory(response.data.scheduledChangesByCategory)
        setCancellingAt(response.data.cancellingAt)
        setState('ready')
      } catch (error) {
        setState('error')
      }
    },
    [appApi]
  )

  useEffect(() => {
    setState('loading')
    const abortController = new AbortController()
    if (loggedIn) void loadSchedule(abortController.signal)
    return () => abortController.abort()
  }, [loadSchedule, loggedIn])

  const onSubscriptionChange = useCallback(
    ({ subscriptionStatus }: OrganizationSubscriptionStateChange) => {
      if (subscriptionStatus === 'payment_in_progress') return
      void loadSchedule()
    },
    [loadSchedule]
  )
  useWebsocketEvent('organization-subscription-state-change', onSubscriptionChange)

  const undoSubscriptionCancel = async (category: PlanCategory) => {
    try {
      const props = { category } as UndoSubscriptionScheduleBody
      await appApi.post<GetSubscriptionScheduleResponse>('/billing-v2/undo-subscription-cancel', props)
    } catch (error) {
      setState('error')
    }
  }

  return {
    state,
    scheduledChangesByCategory,
    cancellingAt,
    undoSubscriptionCancel,
  }
}
