import React, { Component, PureComponent, Fragment } from "react";
export default function SelectField(props) {
  let {
    name,
    options = [],
    value,
    defaultValue,
    id,
    onChange,
    t,
    selectText = "common.selectValue",
    removeText = "common.emptyValue",
    removable = false,
    disabled = false
  } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--select" }, /* @__PURE__ */ React.createElement(
    "select",
    {
      onChange,
      value: value || defaultValue || "",
      name,
      id,
      disabled,
      "data-testid": props["data-testid"]
    },
    /* @__PURE__ */ React.createElement("option", { value: "", disabled: true }, t(selectText)),
    value && removable ? /* @__PURE__ */ React.createElement("option", { value: "" }, "(", t(removeText), ")") : null,
    options.map(({ value: optionValue, label, options: options2 }) => {
      if (!optionValue && Array.isArray(options2))
        return /* @__PURE__ */ React.createElement("optgroup", { label, key: label }, options2.map((o) => /* @__PURE__ */ React.createElement("option", { value: o.value, key: o.value || o.label }, o.label)));
      return /* @__PURE__ */ React.createElement("option", { value: optionValue, key: optionValue || label }, label);
    })
  ));
}
