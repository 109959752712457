import { useContext } from 'react'
import FormContext, { FormContextType } from './formContext'

export { default as CheckBoxField } from './checkboxes'
export { default as Field } from './field'
export { default as Fieldset } from './fieldset'
export { default as Form } from './form'
export { default as FormStore } from './formStore'
export { default as Loader } from './loader'
export { default as Messages } from './messages'
export { default as Submit } from './submit'
export { default as Wizard } from './wizard'
export { FormContext }

export function useForm<T = unknown>() {
  return useContext(FormContext) as FormContextType<T>
}
