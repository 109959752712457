import { ErrorBoundary } from './error/ErrorBoundary'
import { TranslationProvider } from './i18n'
import { Router } from './router'
import Routes from './routes'
import ThemeProvider from './theme'

export const App = () => {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <TranslationProvider>
          <Router>{Routes}</Router>
        </TranslationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
