import { GetPlansResponse } from '@tm/types/api/plans'
import { createContext, useContext } from 'react'

export const BillingPlanContext = createContext<{
  setPlans: (plans: GetPlansResponse['plans']) => void
  plans: GetPlansResponse['plans']
  plansLoading: boolean
  error?: string
}>({ plansLoading: true } as never)

export const useBillingPlans = () => useContext(BillingPlanContext)
