import { createContext } from 'react'

export type Translator = (key: string, replacements?: string[], overrideLang?: string) => string

export interface TranslationContextType {
  t: Translator
  /** Returns true if currently selected language has the translation defined */
  hasTranslation: (key: string) => boolean
  setLanguage: (lang: string) => void
  lang: string
  languages: { [key: string]: string }
  appLanguages: { [key: string]: string }
  brand: string
  locale: string
}

export const TranslationContext = createContext<TranslationContextType>({
  t: () => '',
  hasTranslation: () => false,
  setLanguage: () => 0,
  lang: '',
  languages: {},
  appLanguages: {},
  brand: 'trustmary',
  locale: '',
})
