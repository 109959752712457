import React from 'react'
import { connectI18n } from '../i18n'

const typeIcons = {
  success: 'fa-check-circle',
  info: 'fa-info-circle',
  warning: 'fa-warning',
  error: 'fa-exclamation-circle ',
}

type Message = { text: string; type?: string }

function MessagesElement({ messages = [], t }: { messages: (string | Message)[]; t: (key: string) => string }) {
  if (!messages.length) return null

  return (
    <div className="messages">
      {messages.map((message, key) => {
        if (!message) return

        if (typeof message === 'string') message = { text: message, type: 'error' }

        const { type = 'error' } = message
        let { text = '' } = message

        if (typeof text === 'string' && text.startsWith('error.')) text = t(text)

        const iconClasses = ['fa', typeIcons[type] || '']
        const classes = ['message', `message--${type}`]

        return (
          <div className={classes.join(' ')} /* type={type} */ key={key}>
            <i className={iconClasses.join(' ')} />
            <span className="message-text">{text}</span>
          </div>
        )
      })}
    </div>
  )
}

export default connectI18n(MessagesElement)
