var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { fromJS, Map } from "immutable";
import React from "react";
import CheckboxField from "./checkbox";
import CheckboxConfirmField from "./checkboxConfirm";
import CheckboxesField from "./checkboxes";
import CodeEditorField from "./codeEditor";
import ColorPicker from "./colorPicker";
import ComponentField from "./component";
import ConditionsField from "./conditions";
import ContentField from "./content";
import CountryField from "./country";
import DatetimeField from "./datetime";
import EmbedThemeField from "./embedTheme";
import { default as File, default as FileField } from "./file";
import FormStore from "./formStore";
import IconSelectField from "./iconSelect";
import InputField from "./input";
import LinksField from "./Links";
import MultiSelectField from "./multiselect";
import OptionsField from "./options";
import RadiosField from "./radios";
import RepeaterField from "./repeater";
import SelectField from "./select";
import TemplatesField from "./templates";
import TimeField from "./time";
import TimeofdayField from "./timeofday";
import WysiwgField from "./wysiwyg";
let inputComponents = {
  textfield: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "text" })),
  text: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "text" })),
  email: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "email" })),
  number: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "number" })),
  password: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "password" })),
  hidden: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "hidden" })),
  radios: RadiosField,
  checkboxes: CheckboxesField,
  checkbox: CheckboxField,
  checkboxConfirm: CheckboxConfirmField,
  options: OptionsField,
  select: SelectField,
  links: LinksField,
  wysiwyg: WysiwgField,
  country: CountryField,
  color: ColorPicker,
  colorPicker: ColorPicker,
  conditions: ConditionsField,
  multiselect: MultiSelectField,
  code: CodeEditorField,
  iconSelect: IconSelectField,
  templates: TemplatesField,
  embedTheme: EmbedThemeField,
  content: ContentField,
  file: FileField,
  component: ComponentField,
  repeater: RepeaterField,
  time: TimeField,
  image: (props) => /* @__PURE__ */ React.createElement(File, __spreadProps(__spreadValues({}, props), { fileType: ".jpg,.jpeg,.png,.gif" })),
  video: (props) => /* @__PURE__ */ React.createElement(File, __spreadProps(__spreadValues({}, props), { fileType: ".mp4,.mov,.webm", maxSize: 262144e3 })),
  file: (props) => /* @__PURE__ */ React.createElement(File, __spreadValues({}, props)),
  textarea: (props) => /* @__PURE__ */ React.createElement(InputField, __spreadProps(__spreadValues({}, props), { type: "textarea" })),
  datetime: DatetimeField,
  timeofday: TimeofdayField
};
function FormField(props) {
  let _a = props, {
    name,
    type,
    label,
    description,
    inputLabel = false,
    formState,
    formUpdate,
    formFileUpdate,
    formFileRemove,
    placeholder,
    prefix,
    suffix,
    processValue = false,
    processContext = {},
    options = [],
    simple = false,
    t,
    disabled = false,
    lang,
    mainLang,
    min,
    max,
    style = [],
    hideWhen = false,
    after = false,
    before = false
  } = _a, restprops = __objRest(_a, [
    "name",
    "type",
    "label",
    "description",
    "inputLabel",
    "formState",
    "formUpdate",
    "formFileUpdate",
    "formFileRemove",
    "placeholder",
    "prefix",
    "suffix",
    "processValue",
    "processContext",
    "options",
    "simple",
    "t",
    "disabled",
    "lang",
    "mainLang",
    "min",
    "max",
    "style",
    "hideWhen",
    "after",
    "before"
  ]);
  let isHidden = typeof hideWhen === "function" ? hideWhen.call(null, formState) : false;
  if (isHidden) return null;
  let { values, errors, translate } = formState;
  if (label && translate) label = t(label);
  if (description && translate) description = t(description);
  if (typeof options === "function") options = options.call(null, formState);
  if (Array.isArray(options) && translate)
    options = options.map((o) => {
      if (o.label) o.label = t(o.label);
      if (o.description) o.description = t(o.description);
      return o;
    });
  if (Map.isMap(label)) label = label.get("default");
  let input = inputComponents[type] || false;
  let hasError = errors[name] !== void 0;
  let errorElements = hasError ? errors[name].map((text, key) => /* @__PURE__ */ React.createElement("div", { className: "form-item-error", key }, text)) : [];
  let onChange = typeof processValue !== "function" ? formUpdate : (e) => processValue(e, formUpdate, formState, processContext);
  let inputProps = __spreadValues({
    name,
    inputLabel,
    onChange,
    onFileChange: formFileUpdate,
    onFileRemove: formFileRemove,
    value: values.getIn(name.split("."), ""),
    values,
    placeholder,
    prefix,
    suffix,
    options,
    error: hasError,
    simple,
    t,
    disabled,
    lang,
    mainLang,
    min,
    max,
    translate,
    style
  }, restprops);
  let classes = ["form-item", `form-item--${type}`];
  if (errorElements.length) classes.push("form-item--error");
  if (label) classes.push("form-item--label");
  if (simple) classes.push("form-item--simple");
  if (style.length) classes = classes.concat(style.map((style2) => `form-item-style--${style2}`));
  return /* @__PURE__ */ React.createElement("div", { className: classes.join(" ") }, before ? /* @__PURE__ */ React.createElement("div", { className: "form-item-before" }, before) : null, label ? /* @__PURE__ */ React.createElement("label", { className: "form-item-label", htmlFor: name }, /* @__PURE__ */ React.createElement("span", null, label)) : null, /* @__PURE__ */ React.createElement("div", { className: `form-item-input form-item-input--${type}` }, input ? React.createElement(input, inputProps) : `Input type ${type} not found`, after ? /* @__PURE__ */ React.createElement("div", { className: "form-item-after" }, after) : null), description ? /* @__PURE__ */ React.createElement("div", { className: "form-item-description" }, description) : null, errorElements.length ? /* @__PURE__ */ React.createElement("div", { className: "form-item-errors" }, errorElements) : null);
}
export default function FormFieldWrapper(props) {
  return /* @__PURE__ */ React.createElement(FormStore, null, (formState) => /* @__PURE__ */ React.createElement(FormField, __spreadProps(__spreadValues(__spreadValues({}, props), formState), { t: props.t || formState.t })));
}
