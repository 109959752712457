import { Invoice } from '@tm/server-billing/controller/stripe'
import { BillingAddress } from '@tm/server-billing/routes/patchTaxInfoRoute'
import { BillingInfo, CurrentStripeData, DefaultPaymentMethod } from '@tm/types/billing'
import { List, Map } from 'immutable'
import { createContext, useContext } from 'react'

export const BillingContext = createContext<{
  loading: boolean
  error: string | boolean
  billing: Map<unknown, unknown>
  setupBillingData: (data: BillingInfo) => void
  cards: BillingInfo['cards']
  vat: List<unknown>
  subscription: BillingInfo['subscription'] | undefined
  invoices: Invoice[]
  deleteCardById: (card_id: string) => Promise<void>
  canUpdate: boolean
  canRemoveCard: boolean
  stripeTrial: any
  canCancel: boolean
  cancelInProgress: boolean
  loadBillingData: () => Promise<void>
  setSubscriptionData: (data: BillingInfo['subscription']['data']) => void
  currentStripeData: CurrentStripeData | null
  paymentMethod: DefaultPaymentMethod | null
  billingAddress: BillingAddress | null
}>({
  loading: true,
  error: false,
} as never)

export const useBilling = () => useContext(BillingContext)
