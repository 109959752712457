import 'moment/locale/fi'
import 'moment/locale/sv'
import React, { useContext } from 'react'
import { TranslationContext } from './translationContext'
export * from './translationContext'
export * from './TranslationProvider'

const TranslationStore = TranslationContext.Consumer
export default TranslationStore

export const messageLangs: string[] = ['fi', 'en']

export function connectI18n<T extends object>(component: React.FC<T>): (props: T) => JSX.Element {
  return props => (
    <TranslationStore>{i18nState => React.createElement(component, { ...props, ...i18nState })}</TranslationStore>
  )
}

export function useI18n() {
  return useContext(TranslationContext)
}
