import {
  AttachTargetBody,
  AttachTargetResult,
  CreateTagBody,
  CreateTagResult,
  DetachTargetBody,
  DetachTargetResult,
  GetSuggestionsResult,
} from '@tm/types/common/app-api/tag'
import { Tag } from '@tm/types/common/tag'
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ApiContext } from '../api'
import { useI18n } from '../i18n'
import { translateSystemTag } from './translateSystemTag'

interface TagContextType {
  suggestions: Tag[]
  error: string | boolean
  attachTag: (tag: Tag, target: string, target_id: string) => Promise<AttachTargetResult | null>
  detachTag: (tag_id: string, target: string, target_id: string) => Promise<DetachTargetResult | null>
  fetchTags: () => void
  createTag: (tag_name: string) => Promise<CreateTagResult | null>
}

export const TagContext = createContext<TagContextType>({} as never)

export default function TagProvider(props: { children: React.ReactNode }) {
  const { t } = useI18n()
  const { children } = props
  const { appApi } = useContext(ApiContext)
  const loading = useRef(false)

  const [error, setError] = useState<string | boolean>(false)
  const [suggestions, setSuggestions] = useState<Tag[]>([])

  const fetchTags = () => {
    if (loading.current) return
    loading.current = true

    appApi
      .get<GetSuggestionsResult>('/tag/suggestions')
      .then(res => res.data)
      .then(data => {
        setSuggestions((data.suggestions || []).map(tag => ({ ...tag, name: translateSystemTag(t, tag.name) })))
      })
      .catch(err => {
        console.log(err)
        setError(true)
      })
  }

  /**
   * Methods
   */

  const attachTag = async (tag: Tag, target: string, target_id: string) => {
    const { id: tag_id, name: tag_name } = tag
    return await appApi
      .post<AttachTargetResult, AttachTargetBody>('/tag/attach', { tag_id, tag_name, target, target_id })
      .then(res => res.data)
      .then(data => {
        // TODO: Check if we want to attach the tag with the current info
        if (data.tag) setSuggestions([...suggestions, { ...tag, id: data.tag.id }])

        return data
      })
      .catch(err => {
        console.log(err)
        setError((err as Error).message || true)
        return null
      })
  }

  const detachTag = (tag_id: string, target: string, target_id: string) => {
    return appApi
      .post<DetachTargetResult, DetachTargetBody>('/tag/detach', { tag_id, target, target_id })
      .then(res => res.data)
      .catch(err => {
        console.log(err)
        setError((err as Error).message || true)
        return null
      })
  }

  const createTag = useCallback(
    (tag_name: string) => {
      return appApi
        .post<CreateTagResult, CreateTagBody>('/tag', { tag_name })
        .then(res => res.data)
        .then(data => {
          const isCreated = data.tag_id && !suggestions.find(tag => tag.id === data.tag_id)
          if (isCreated) setSuggestions([...suggestions, { name: tag_name, id: data.tag_id, data: {} }])

          return data
        })
        .catch(err => {
          console.log(err)
          setError((err as Error).message || true)
          return null
        })
    },
    [appApi, setSuggestions, suggestions, setError]
  )

  const contextValue: TagContextType = {
    suggestions,
    error,
    attachTag,
    detachTag,
    fetchTags,
    createTag,
  }

  return <TagContext.Provider value={contextValue}>{children}</TagContext.Provider>
}

export function useTags() {
  const { suggestions, fetchTags, ...rest } = useContext(TagContext)

  useEffect(() => {
    fetchTags()
  }, [])

  return {
    ...rest,
    suggestions,
    tagOptions: suggestions.map(t => ({
      value: t.id,
      label: t.name,
    })),
  }
}
