import { GetPlansResponse, PlanCategory } from '@tm/types/api/plans'
import { BillingInfo } from '@tm/types/billing'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { ScheduledChangesByCategory } from '../../../../../server/app-api/billing-v2/routes/getSubscriptionScheduleRoute'
import { useBilling } from './useBilling'
import { useBillingPlans } from './useBillingPlans'
import { useSubscriptionSchedules } from './useSubscriptionSchedules'

export interface BillingPlanInfoContextType {
  /** Active upgrade plans */
  plans: GetPlansResponse['plans']
  /** String representation of an error in billing plan info context */
  error?: string
  /** User's current active subscription */
  subscription: BillingInfo['subscription'] | undefined
  /** Currently active currency */
  currency?: string
  /** Are the upgrade plans still being fetched? */
  plansLoading: boolean
  /** Is the billing data still being fetched? */
  billingLoading: boolean
  /** Full state returned by useBilling*/
  billingState: ReturnType<typeof useBilling>
  /** Scheduled changes for the subscription plans */
  scheduledChangesByCategory?: ScheduledChangesByCategory
  /** Subscription cancelling at*/
  subscriptionCancellingAt?: Date | null
  /** Undo scheduled cancel action */
  undoSubscriptionCancel: (category: PlanCategory) => Promise<void>
}

export const BillingPlanInfoContext = createContext<BillingPlanInfoContextType>({} as never)

/**
 * Context provider that provides information on available upgrade plans
 * and handles the fetching of the upgrade plan data.
 * @returns Context provider for the BillingPlanInfoContext
 */
export const BillingPlanInfoContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [overrideHandled, setOverrideHandled] = useState(false)
  const { plans, setPlans, plansLoading, error } = useBillingPlans()
  const {
    scheduledChangesByCategory,
    state: scheduleChangeState,
    cancellingAt: subscriptionCancellingAt,
    undoSubscriptionCancel,
  } = useSubscriptionSchedules()

  const billingState = useBilling()
  const { billing, subscription, loading: billingLoading } = billingState

  /**
   * If the user has an active subscription, we want to use the info from that
   * subscription to override pricing and limit data with the user's current
   * price and data for the current plan.
   */
  useEffect(() => {
    if (subscription?.data && !overrideHandled && plans.length) {
      const period = subscription.data.useAnnualPrice ? 'year' : 'month'
      const newPlans = plans.map(plan => {
        const newPlan = { ...plan }
        if (subscription.data.plans) {
          const overridePlan = subscription.data.plans.find(
            plan =>
              plan.planId === newPlan.planId && newPlan.period == period && plan.currency === newPlan.price.currency
          )
          if (overridePlan) {
            newPlan.pricingId = overridePlan.stripePriceId
            newPlan.price.price = overridePlan.price
          }
        }
        return newPlan
      })
      setPlans(newPlans)
      setOverrideHandled(true)
    }
  }, [subscription, overrideHandled, plans, billing, setPlans])

  const value = useMemo<BillingPlanInfoContextType>(
    () => ({
      plans,
      error,
      currency: billing.get('currency') as string,
      subscription,
      plansLoading: plansLoading || scheduleChangeState === 'loading',
      billingLoading,
      billingState,
      scheduledChangesByCategory,
      subscriptionCancellingAt,
      undoSubscriptionCancel,
    }),
    [
      plans,
      error,
      billing,
      subscription,
      plansLoading,
      scheduleChangeState,
      billingLoading,
      billingState,
      scheduledChangesByCategory,
      subscriptionCancellingAt,
      undoSubscriptionCancel,
    ]
  )

  return <BillingPlanInfoContext.Provider value={value}>{children}</BillingPlanInfoContext.Provider>
}
