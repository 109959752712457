export const units = ["m", "h", "d", "M"];
import * as moment from "moment";
export function parseTime(value) {
  value = typeof value === "string" ? value : "0d";
  let unit = value.substr(-1);
  let count = parseInt(value);
  if (!count || count < 0) count = 0;
  if (!units.includes(unit)) unit = "d";
  return { unit, count };
}
export function formatTime(str, t, timeType = "timePast") {
  let { unit, count } = parseTime(str);
  let singular = parseInt(count) === 1 && timeType === "timePast";
  return `${count} ${t(`embedPopup.rules.${timeType}.${unit}${singular ? "1" : ""}`)}`;
}
export function formatTimeOfDay(int, valueResolution = "minutes", format = "LT") {
  if (typeof int !== "number") throw new Error("formatTimeOfDay requires a number");
  return moment.utc().startOf("day").add(moment.duration(int, valueResolution)).local().format(format);
}
