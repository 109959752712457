import React, { Component, PureComponent, Fragment } from "react";
import { Map, List, fromJS } from "immutable";
export default function Checkboxes({ name, options = [], t, value, onChange }) {
  if (!List.isList(value)) value = List();
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--checkboxes" }, options.length ? options.map(({ value: optionValue, label }) => {
    let classes = ["form-checkbox"];
    let isChecked = value.contains(optionValue);
    if (isChecked) classes.push("form-checkbox--checked");
    return /* @__PURE__ */ React.createElement(
      "button",
      {
        className: classes.join(" "),
        onClick: (e) => e.preventDefault() & onChange({ name, value: isChecked ? value.filter((v) => v !== optionValue) : value.push(optionValue) }),
        key: optionValue
      },
      /* @__PURE__ */ React.createElement("span", { className: "form-checkbox-icon-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "form-checkbox-icon" }, isChecked ? /* @__PURE__ */ React.createElement("i", { className: "fa fa-check" }) : null)),
      /* @__PURE__ */ React.createElement("span", { className: "form-checkbox-label" }, label)
    );
  }) : /* @__PURE__ */ React.createElement("div", { className: "form-checkbox-empty" }, t("common.emptyList")));
}
