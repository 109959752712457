import React, { useCallback, useState } from 'react'
import { ConfirmDialog } from '../common/confirmDialog'
import { Translator } from '../i18n'

interface CheckboxProps {
  inputLabel?: string | JSX.Element
  style?: string
  name: string
  value: boolean
  onValue?: boolean
  offValue?: boolean
  onChange: (data: { name: string; value: boolean }) => void
  disabled?: boolean
  t: Translator
  confirmationTitleChecked: string
  confirmationTitleUnchecked: string
  confirmationDescription: string
}

export default function CheckboxConfirm(props: CheckboxProps) {
  const {
    inputLabel: label = '',
    style,
    name,
    value,
    onChange,
    onValue = true,
    offValue = false,
    disabled = false,
    t,
    confirmationTitleChecked,
    confirmationTitleUnchecked,
    confirmationDescription,
  } = props

  const [confirmOpen, setConfirmOpen] = useState(false)

  const classes = ['form-checkbox']
  const isChecked = value === onValue

  if (isChecked) classes.push('form-checkbox--checked')

  const wrapperClasses = ['form-checkbox-wrapper']
  if (style) wrapperClasses.push(`form-checkbox-wrapper--${style}`)

  const handleAutopublishToggle = useCallback(() => {
    onChange({ name, value: isChecked ? offValue : onValue })
    setConfirmOpen(false)
  }, [onChange, name, isChecked, offValue, onValue])

  const handleAutopublishCancel = useCallback(() => {
    setConfirmOpen(false)
  }, [])

  const labelClasses = ['form-checkbox-label']
  if (disabled) labelClasses.push('form-checkbox-label-disabled')

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={classes.join(' ')}>
        <button
          className="form-checkbox-icon-wrapper"
          disabled={disabled}
          onClick={e => {
            e.preventDefault()
            setConfirmOpen(true)
          }}>
          <span className="form-checkbox-icon">{isChecked ? <i className="fa fa-check" /> : null}</span>
        </button>
        <div
          className={labelClasses.join(' ')}
          onClick={e => {
            if (disabled) return
            if (e.target instanceof HTMLDivElement && e.target.tagName === 'a') {
              return
            }
            setConfirmOpen(true)
          }}>
          {label}
        </div>
      </div>
      <ConfirmAutopublish
        open={confirmOpen}
        onConfirm={handleAutopublishToggle}
        onCancel={handleAutopublishCancel}
        t={t}
        title={isChecked ? confirmationTitleChecked : confirmationTitleUnchecked}
        description={confirmationDescription}
      />
    </div>
  )
}

function ConfirmAutopublish({
  t,
  open,
  onConfirm,
  onCancel,
  title,
  description,
}: {
  t: Translator
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  title: string
  description: string
}) {
  return (
    <ConfirmDialog
      t={t}
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      title={title}
      description={description}
    />
  )
}
