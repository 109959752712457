import React from "react";
export default function Radios({ name, options = [], value, onChange, disabled = false }) {
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--radios", role: "radiogroup" }, options.map(({ value: optionValue, label }) => {
    let classes = ["form-radio"];
    let isChecked = optionValue === value;
    if (isChecked) classes.push("form-radio--checked");
    return /* @__PURE__ */ React.createElement(
      "button",
      {
        className: classes.join(" "),
        onClick: (e) => e.preventDefault() & onChange({ name, value: optionValue }),
        disabled,
        key: optionValue
      },
      /* @__PURE__ */ React.createElement("span", { className: "form-radio-icon-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "form-radio-icon" }, isChecked ? /* @__PURE__ */ React.createElement("i", { className: "fa fa-check" }) : null)),
      /* @__PURE__ */ React.createElement("span", { className: "form-radio-label" }, label)
    );
  }));
}
