var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { fromJS, List, Map } from "immutable";
import React, { Component } from "react";
import { connectApi } from "../api/connectApi";
import FormContext from "./formContext";
import Loader from "./loader";
import Messages from "./messages";
import { uploadFiles } from "./uploadFile";
import Validator from "./validator";
export function getExtension(name) {
  if (typeof name !== "string") return null;
  let nameParts = name.split(".");
  return nameParts[nameParts.length - 1];
}
let wait = (fn) => {
  let threshhold = 500;
  let deferTimer = void 0;
  return function() {
    let args = arguments;
    if (deferTimer) clearTimeout(deferTimer);
    deferTimer = setTimeout(function() {
      fn.apply(this, args);
    }, threshhold);
  };
};
class Form extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "state", {
      formState: {
        //current values in the form user is using
        values: Map(),
        //changes that user has made since loading the form
        changes: Map(),
        //savedValues is what is supposed to be in DB, to help saving values partially
        savedValues: Map(),
        //pending changes to mark what is in process of saving to data store
        pendingChanges: Map(),
        errors: {},
        errorsMap: Map(),
        messages: [],
        translate: false
      },
      formLoading: false
    });
    __publicField(this, "handleFormInit", (values) => {
      if (!Map.isMap(values)) values = fromJS(values);
      let { formState } = this.state;
      formState.values = values;
      formState.errors = {};
      formState.messages = [];
      this.setState({ formState });
    });
    __publicField(this, "autosaveWait", wait((fn) => fn()));
    __publicField(this, "autosave", () => this.autosaveWait(() => this.handleFormSubmit()));
    __publicField(this, "handleFormUpdate", (events, callback = false) => {
      if (!Array.isArray(events)) events = [events];
      let { processors = {}, autoUpdate = {}, autosave } = this.props;
      let formState = __spreadValues({}, this.state.formState);
      formState = events.reduce((formState2, event) => {
        let { values, changes } = formState2;
        let target = event.target || event;
        let { name, value } = target;
        if (processors[name] != null) value = Processor(value, processors[name]);
        if (autoUpdate[name] != null) {
          for (let autoName of autoUpdate[name]) {
            let autoValue = value;
            if (processors[autoName]) autoValue = Processor(autoValue, processors[autoName]);
            values = values.setIn(_name.split("."), _value);
            changes = changes.setIn(_name.split("."), _value);
          }
        }
        if (value !== void 0) {
          values = values.setIn(name.split("."), value);
        } else {
          values = values.removeIn(name.split("."));
        }
        changes = changes.setIn(name.split("."), value);
        formState2.values = values;
        formState2.changes = changes;
        return formState2;
      }, formState);
      if (autosave) this.autosave();
      this.setState(
        {
          formState
        },
        () => {
          if (typeof callback === "function") callback();
        }
      );
    });
    __publicField(this, "handleFormFileUpdate", (data) => {
      let { name, value: files, multiple = false, fileType = "*", maxSize = 10485760 } = data;
      let acceptedFiletypes = fileType === "*" ? [] : fileType.split(",").map((f) => f.trim().replace(".", ""));
      let { t, autoUpload = false, autosave = false, api } = this.props;
      let { formState } = this.state;
      let { values, errors, changes } = formState;
      if (!files) {
        delete formState.errors[name];
        formState.values = formState.values.removeIn(name.split("."));
        return this.setState({ formState });
      }
      let value = multiple ? values.getIn(name.split("."), List()) : List();
      let fileErrors = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let fileError = false;
        let { size, type, name: name2 } = file;
        let extension = getExtension(name2);
        if (value.find((f) => f.name === name2)) fileError = t("file.errorFileTaken");
        if (size > maxSize) {
          fileError = t("file.errorMaxSize");
        } else if (acceptedFiletypes.length && acceptedFiletypes.indexOf(extension.toLowerCase()) === -1) {
          fileError = t("file.errorWrongType");
        }
        if (fileError) {
          fileErrors.push(`${t("file.error", "Error")}: ${name2} ${fileError}`);
          continue;
        }
        file.preview = URL.createObjectURL(file);
        value = value.push(file);
      }
      if (fileErrors.length) {
        errors[name] = fileErrors;
      } else {
        delete errors[name];
      }
      if (autoUpload && value.size) {
        uploadFiles(api, value).then((value2) => {
          formState.values = values.setIn(name.split("."), multiple ? value2 : value2.first());
          formState.changes = changes.setIn(name.split("."), multiple ? value2 : value2.first());
          formState.errors = errors;
          this.setState({ formState }, () => {
            if (autosave) this.autosave();
          });
        });
      } else {
        formState.values = values.setIn(name.split("."), value);
        formState.errors = errors;
        this.setState({ formState });
      }
    });
    __publicField(this, "handleFormFileRemove", (data) => {
      let { name, fileKey } = data;
      let { autosave = false } = this.props;
      let { formState } = this.state;
      let { values } = formState;
      let value = values.getIn(name.split("."), List());
      if (!List.isList(value)) {
        formState.values = values.removeIn(name.split("."));
      } else {
        formState.values = values.setIn(name.split("."), value.remove(fileKey));
      }
      if (autosave) this.autosave();
      this.setState({ formState });
    });
    /**
     * submit form to save fields to DB
     * @param {*} e
     * @param {*} fieldsToSave if specified, only saves the listed fields
     */
    __publicField(this, "handleFormSubmit", (e, fieldsToSave) => {
      if (e && e.defaultPrevented) return;
      if (e && e.preventDefault) e.preventDefault();
      let { validation: propValidation = false, onSubmit } = this.props;
      let stateUpdate = {};
      let { formState } = this.state;
      let { values, changes, savedValues } = formState;
      let errors = {};
      let errorsMap = Map();
      let validation = false;
      let valuesToSave = values;
      formState.pendingChanges = changes;
      if (fieldsToSave && fieldsToSave.length > 0) {
        valuesToSave = savedValues;
        formState.pendingChanges = Map();
        fieldsToSave.forEach((f) => {
          valuesToSave = valuesToSave.set(f, changes.get(f));
          formState.pendingChanges = formState.pendingChanges.set(f, changes.get(f));
        });
      }
      if (typeof propValidation === "function") {
        validation = propValidation(this.props, valuesToSave, formState.pendingChanges);
      } else if (typeof propValidation === "object") {
        validation = propValidation;
      }
      errors = typeof validation === "function" ? validation(valuesToSave, this.props.t) : validation ? Validator(valuesToSave, validation, this.props.t) : {};
      if (!Object.keys(errors).length) {
        stateUpdate.formState = formState;
        this.setState(stateUpdate);
        let submitReturn = onSubmit.call(this, valuesToSave, formState.pendingChanges, this.handleFormSubmitDone);
        if (submitReturn && typeof submitReturn.then === "function")
          submitReturn.then((res) => this.handleFormSubmitDone(res)).catch((err) => this.handleFormSubmitDone(err.response || err.data || err));
        stateUpdate.formLoading = true;
      } else {
        for (let name in errors) {
          errorsMap = errorsMap.setIn(name.split("."), errors[name]);
        }
      }
      formState.errors = errors;
      formState.errorsMap = errorsMap;
      stateUpdate.formState = formState;
      this.setState(stateUpdate);
    });
    __publicField(this, "handleFormSubmitDone", (resState) => {
      let prevChanges = this.state.formState.changes;
      let prevValues = this.state.formState.values;
      if (typeof resState !== "object") resState = {};
      this.state.formState.pendingChanges.forEach(
        (k, c) => resState.savedValues = this.state.formState.savedValues.set(c, k)
      );
      let state = {};
      state.formLoading = false;
      if (resState.errors) {
        resState.errorsMap = Map();
        for (let name in resState.errors) {
          let value = resState.errors[name];
          resState.errorsMap = resState.errorsMap.setIn(name.split("."), value);
        }
      }
      if (resState.message) {
        resState.messages = resState.messages || [];
        resState.messages.push({
          type: "error",
          text: resState.message
        });
      }
      resState.changes = this.state.formState.changes;
      this.state.formState.pendingChanges.forEach((_, c) => resState.changes = resState.changes.delete(c));
      resState.pendingChanges = Map();
      if (Array.isArray(resState.messages))
        state.messages = resState.messages.map(
          (message) => typeof message === "string" ? { type: "error", text: message } : message
        );
      state.formState = Object.assign({}, this.state.formState, resState);
      this.setState(state, () => {
        if (typeof this.submitListener === "function") this.submitListener(prevChanges, prevValues);
      });
    });
    __publicField(this, "handleSetLoading", (loading) => {
      this.setState({
        formLoading: typeof loading === "boolean" ? loading : this.state.formLoading === false
      });
    });
    let { values, translate = false } = props;
    if (!Map.isMap(values)) values = fromJS(values);
    if (!Map.isMap(values)) return;
    let { formState } = this.state;
    formState.values = values;
    formState.savedValues = values;
    formState.pendingChanges = Map();
    formState.errors = {};
    formState.messages = [];
    formState.translate = translate;
    this.state.formState = formState;
  }
  render() {
    let {
      id,
      children,
      t,
      className = ["form"],
      stickySubmit = false,
      stickyMessages = false,
      fieldsetPadding = false,
      disableLoader = false,
      disableMessages = false,
      simple = false,
      box
    } = this.props;
    let { formState, formLoading } = this.state;
    if (formLoading) className.push("form--processing");
    if (stickySubmit) className.push("form--sticky-submit");
    if (stickyMessages) className.push("form--sticky-messages");
    if (fieldsetPadding) className.push("form--fieldset-padding");
    if (simple) className.push("form--simple");
    if (box) className.push("form--box");
    let contextValue = {
      typedValues: formState.values.toJS(),
      formState,
      formLoading,
      formUpdate: this.handleFormUpdate,
      formSubmit: this.handleFormSubmit,
      formFileUpdate: this.handleFormFileUpdate,
      formFileRemove: this.handleFormFileRemove,
      formInit: this.handleFormInit,
      formSetLoading: this.handleSetLoading,
      formSetSubmitListener: (listener) => this.submitListener = listener,
      t
    };
    return /* @__PURE__ */ React.createElement(FormContext.Provider, { value: contextValue }, /* @__PURE__ */ React.createElement("form", { id, onSubmit: this.handleFormSubmit, className: className.join(" ") }, disableMessages || /* @__PURE__ */ React.createElement(Messages, { messages: formState.messages || [] }), children, !disableLoader && formLoading ? /* @__PURE__ */ React.createElement(Loader, null) : null));
  }
}
export default connectApi(Form);
