var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { Component, PureComponent, Fragment } from "react";
import { Map, List, fromJS } from "immutable";
const formatBytes = (a, b) => {
  if (0 == a) return "0 B";
  let c = 1024, d = b || 2, e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};
const getExtension = (name) => {
  let parts = name.split(".");
  return parts[parts.length - 1];
};
const getFileIcon = (extension) => {
  if (["png", "jpg", "jpeg", "gif"].includes(extension)) return "image";
  if (["csv", "xls", "xlsx"].includes(extension)) return "table";
  return "question";
};
function BoxFile(props) {
  let {
    onFileChange,
    onFileRemove,
    name,
    value,
    t,
    fileType = ".jpg,.jpeg,.png,.gif,.mp4,.mpeg,.doc,.docx,.pdf,.xls,xlsx,.csv,.txt,.zip",
    maxSize = 10485760
  } = props;
  let multiple = false;
  let defaultData = { name, fileType, multiple, maxSize };
  if (typeof value === "string" && value.length) value = List().push(value);
  if (!List.isList(value)) value = List();
  let file = value.first();
  if (typeof file === "string") file = { preview: file, name: file.split("/").pop() };
  let hasFile = file && file.preview;
  return /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file" }, hasFile ? /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file-preview" }, file.preview ? /* @__PURE__ */ React.createElement("img", { src: file.preview, alt: file.name }) : /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file-icon" }, /* @__PURE__ */ React.createElement("i", { className: `fa fa-${getFileIcon(getExtension(file.name))}` }))) : /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file-placeholder" }, /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file-icon" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-upload" }))), /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file-input" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "file",
      accept: fileType,
      multiple,
      onChange: (e) => onFileChange(__spreadValues({ value: e.target.files }, defaultData))
    }
  )), hasFile && /* @__PURE__ */ React.createElement("div", { className: "form-field-box-file-remove" }, /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & onFileRemove({ name, fileKey: 0 }) }, /* @__PURE__ */ React.createElement("span", null, t("common.remove")))));
}
export default function FileField(props) {
  let {
    onFileChange,
    onFileRemove,
    name,
    value,
    t,
    multiple = false,
    fileType = ".jpg,.jpeg,.png,.gif,.mp4,.mpeg,.doc,.docx,.pdf,.xls,xlsx,.csv,.txt,.zip",
    maxSize = 10485760,
    simple = false,
    inputLabel = false,
    inputIcon = false,
    info,
    box = false
  } = props;
  if (box) return /* @__PURE__ */ React.createElement(BoxFile, __spreadValues({}, props));
  info = info || fileType.split(",").join("/");
  let defaultData = { name, fileType, multiple, maxSize };
  if (typeof value === "string" && value.length) value = List().push(value);
  if (!List.isList(value) || !value.size) {
    return /* @__PURE__ */ React.createElement("div", { className: "form-field-file" }, /* @__PURE__ */ React.createElement("div", { className: "form-field-file-upload" }, /* @__PURE__ */ React.createElement("div", { className: "form-field-file-upload-text" }, /* @__PURE__ */ React.createElement("i", { className: inputIcon ? inputIcon : "fa fa-file" }), inputLabel ? inputLabel : t("file.upload"), simple ? null : /* @__PURE__ */ React.createElement("span", { className: "form-field-file-upload-info" }, ` (${fileType !== "*" ? fileType.split(",").join("/") + ", " : ""}max. ${formatBytes(maxSize)})`)), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "file",
        accept: fileType,
        multiple,
        onChange: (e) => onFileChange(__spreadValues({ value: e.target.files }, defaultData))
      }
    )));
  }
  return /* @__PURE__ */ React.createElement("div", { className: "form-field-file form-field-file--selected" }, value.map((file, fileKey) => {
    if (typeof file === "string")
      file = {
        preview: file,
        name: file.split("/").pop()
      };
    let { preview, name: fileName = "", size = false } = file;
    let fileIcon = getFileIcon(getExtension(fileName));
    return /* @__PURE__ */ React.createElement("div", { className: "form-field-file-item", key: fileKey }, fileIcon === "image" ? /* @__PURE__ */ React.createElement("div", { className: "form-field-file-item-preview" }, /* @__PURE__ */ React.createElement("img", { src: preview, alt: fileName })) : /* @__PURE__ */ React.createElement("div", { className: "form-field-file-item-preview form-field-file-item-preview--icon" }, /* @__PURE__ */ React.createElement("i", { className: `fa fa-${fileIcon}` })), /* @__PURE__ */ React.createElement("div", { className: "form-field-file-item-name" }, /* @__PURE__ */ React.createElement("span", { className: "ellipsis" }, fileName, " ", size && /* @__PURE__ */ React.createElement("span", { className: "form-field-file-item-name-size" }, "(", formatBytes(size), ")"))), /* @__PURE__ */ React.createElement("div", { className: "form-field-file-item-remove" }, /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & onFileRemove({ name, fileKey }) }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-trash" }))));
  }));
}
