import React, { useState, useEffect, useRef } from "react";
import { Map, List, fromJS } from "immutable";
import Radios from "./radios";
export default function Templates(props) {
  let {
    value,
    name,
    onChange,
    loading = false,
    templates,
    templateTypeField = "type",
    templateSourceField = "source",
    values,
    lang,
    sourceOptions,
    t
  } = props;
  let type = values.get(templateTypeField);
  let source = values.get(templateSourceField);
  let [templateOptions, setTemplateOptions] = useState(List());
  let prevConfig = useRef();
  useEffect(() => {
    let typeSource = type + source + loading.toString();
    if (prevConfig.current !== typeSource) {
      let items = templates.getIn([source, type], List());
      setTemplateOptions(items);
      if (items.size) onChange({ name, value: items.getIn([0, "id"]) });
    }
    prevConfig.current = typeSource;
  });
  return /* @__PURE__ */ React.createElement("div", { className: "templates" }, /* @__PURE__ */ React.createElement("div", { className: "templates-source" }, /* @__PURE__ */ React.createElement(Radios, { name: templateSourceField, value: source, onChange, options: sourceOptions })), source !== "empty" ? /* @__PURE__ */ React.createElement("div", { className: "templates-list" }, !List.isList(templateOptions) || !templateOptions.size ? /* @__PURE__ */ React.createElement("div", { className: "templates-empty" }, t("templates.empty")) : templateOptions.map((template) => {
    let id = template.get("id");
    let isSelected = id === value;
    let title = template.getIn(["info", lang, "title"], template.getIn(["info", "en", "title"]));
    let description = template.getIn(
      ["info", lang, "description"],
      template.getIn(["info", "en", "description"])
    );
    let classes = ["templates-item"];
    if (isSelected) classes.push("templates-item--selected");
    return /* @__PURE__ */ React.createElement("div", { className: "templates-list-item", key: id }, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: classes.join(" "),
        onClick: (e) => e.preventDefault() & onChange({ name, value: id })
      },
      /* @__PURE__ */ React.createElement("div", { className: "templates-item-content" }, /* @__PURE__ */ React.createElement("div", { className: "templates-item-content-inner" }, /* @__PURE__ */ React.createElement("div", { className: "templates-item-title" }, title), description ? /* @__PURE__ */ React.createElement("div", { className: "templates-item-description" }, description) : null)),
      /* @__PURE__ */ React.createElement("div", { className: "templates-item-info" })
    ));
  })) : null);
}
