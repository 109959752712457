// Sentry logging
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { WindowGlobals } from './window'
const { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } = window as unknown as WindowGlobals

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    ignoreErrors: [
      /Non-Error promise rejection captured with value\: Object Not Found Matching Id:\d+/,
      'ResizeObserver loop limit exceeded',
      'ChunkLoadError',
      // Bots?
      /Object Not Found Matching Id.+MethodName/,
      // Tawk
      "Cannot read properties of undefined (reading '$socket')",
    ],
  })
}
