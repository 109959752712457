import { UploadFileRouteBody, UploadFileRouteResponse } from '@tm/server-api/routes/file/uploadFileRoute'
import {
  UploadPrivateFileRouteBody,
  UploadPrivateFileRouteResponse,
} from '@tm/server-api/routes/file/uploadPrivateFileRoute'
import axios from 'axios'
import { List, fromJS } from 'immutable'
import { AppApi, request } from '../api'

declare const APP_API_ROOT: string

/**
 * Normally we get api from react context, but with wysiwyg we can't do that
 * So here we create a fallback fileApi object that uses appApi posting
 */
const fileApi = {
  post: <T, V>(path: string, data: V) => request<T, V>('POST', APP_API_ROOT + path, data, false),
  uploadFilePath: '/file',
}

export function uploadFiles(api: AppApi, files: List<string | File>) {
  if (!List.isList(files)) return Promise.resolve(List())

  return Promise.all(files.toJS().map((file: string | File) => uploadFile(api, file))).then(files => fromJS(files))
}

export function uploadFile(api: AppApi | typeof fileApi | null, file: string | File) {
  api = api || fileApi // Default to fallback fileApi
  const { uploadFilePath = '/upload-file' } = api

  if (typeof file === 'string') return Promise.resolve(file)

  return api
    .post<UploadFileRouteResponse, UploadFileRouteBody>(uploadFilePath, {
      filename: file.name,
      size: file.size,
    })
    .then(({ data }) => data.data || {})
    .then(({ postData, filename, fileurl }) => {
      const formData = new FormData()
      for (const key in postData.fields) {
        formData.append(key, postData.fields[key])
      }

      formData.append('file', file, filename)

      return axios({
        method: 'POST',
        url: postData.url,
        data: formData,
      }).then(() => fileurl)
    })
}

export function uploadPrivateFile(api: AppApi, file: File) {
  return api
    .post<UploadPrivateFileRouteResponse, UploadPrivateFileRouteBody>(
      `/file/private`,
      { filename: file.name, size: file.size },
      { timestamp: new Date().getTime() }
    )
    .then(({ data }) => data.data || {})
    .then(({ postData, filename, fileurl, fileId }) => {
      const formData = new FormData()
      for (const key in postData.fields) {
        formData.append(key, postData.fields[key])
      }

      formData.append('file', file, filename)

      return axios({
        method: 'POST',
        url: postData.url,
        data: formData,
      }).then(() => ({ fileurl, fileId }))
    })
}
