import { Box, LinearProgress } from '@mui/material'

type PageLoaderProps = {
  zIndex?: number
  absolutePosition?: boolean
}

export function PageLoader(props: PageLoaderProps) {
  return (
    <Box
      sx={{
        position: props.absolutePosition ? 'absolute' : 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: props.zIndex,
      }}>
      <LinearProgress />
    </Box>
  )
}

export default PageLoader
