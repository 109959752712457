import axios, { AxiosResponse, AxiosStatic } from 'axios'
import { Map } from 'immutable'
import queryString from 'query-string'
import React, { createContext, useContext, useMemo } from 'react'
import { TranslationContextType, useI18n } from '../i18n'
import { request, setApiToken } from './request'

export { request }

const processQuery = (path: string, query: { [field: string]: string | number | undefined }, defaultQuery = {}) => {
  query = Object.assign({}, defaultQuery, query)
  if (Object.keys(query).length) {
    const queryParts = []

    for (const key in query) {
      const value = query[key]
      if (value === undefined) continue

      if (Array.isArray(value)) {
        value.forEach(val => queryParts.push(key + '[]=' + encodeURIComponent(val)))
      } else {
        queryParts.push(key + '=' + encodeURIComponent(value))
      }
    }

    path = `${path}?${queryParts.join('&')}`
  }
  return path
}

export interface ApiContextType extends TranslationContextType {
  axios: AxiosStatic
  appApi: AppApi
  setApiToken: (state: string | boolean) => void
}

export const ApiContext = createContext<ApiContextType>({} as never)
export const ApiStore = ApiContext.Consumer

interface WindowGlobals extends Window {
  APP_API_ROOT: string
}

type Query = { [name: string]: string | number | undefined }
interface LoginResponse {
  organization_id: string
  token: string
}

export interface AppApi {
  get<R>(path: string, query?: Query, logoutOn403?: boolean, signal?: AbortSignal): Promise<AxiosResponse<R>>
  post<R, P = unknown>(
    path: string,
    data?: Map<string, unknown> | P,
    query?: Query,
    logoutOn403?: boolean,
    signal?: AbortSignal
  ): Promise<AxiosResponse<R>>
  patch<R = void, P = unknown>(
    path: string,
    data: Partial<P>,
    query?: Query,
    logoutOn403?: boolean,
    signal?: AbortSignal
  ): Promise<AxiosResponse<R>>
  put<R = void, P = Record<string, unknown>>(
    path: string,
    data: P,
    query?: Query,
    logoutOn403?: boolean,
    signal?: AbortSignal
  ): Promise<AxiosResponse<R>>
  delete<R = void, P = Record<string, unknown>>(
    path: string,
    data?: P,
    query?: Query,
    logoutOn403?: boolean,
    signal?: AbortSignal
  ): Promise<AxiosResponse<R>>
  sendMagickLink(email: string, pwa?: boolean): Promise<AxiosResponse<{ success: boolean }>>
  loginWithMagicLink(email: string, secret: string): Promise<AxiosResponse<LoginResponse>>
  uploadFilePath: string
}

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const i18nState = useI18n()
  const { lang } = i18nState
  const defaultQuery = { lang }
  // TODO: Fix globals
  const APP_API_ROOT = (window as unknown as WindowGlobals).APP_API_ROOT
  const appApi: AppApi = useMemo(() => {
    return {
      get(path: string, query = {}, logoutOn403 = true, signal?: AbortSignal) {
        return request('GET', APP_API_ROOT + processQuery(path, query, defaultQuery), {}, logoutOn403, signal)
      },
      post<T>(path: string, data: T, query = {}, logoutOn403 = true, signal?: AbortSignal) {
        return request('POST', APP_API_ROOT + processQuery(path, query, defaultQuery), data, logoutOn403, signal)
      },
      patch<T>(path: string, data: Partial<T>, query = {}, logoutOn403 = true, signal?: AbortSignal) {
        return request('PATCH', APP_API_ROOT + processQuery(path, query, defaultQuery), data, logoutOn403, signal)
      },
      put<T>(path: string, data: T, query = {}, logoutOn403 = true, signal?: AbortSignal) {
        return request('PUT', APP_API_ROOT + processQuery(path, query, defaultQuery), data, logoutOn403, signal)
      },
      delete<T>(path: string, data: T, query = {}, logoutOn403 = true, signal?: AbortSignal) {
        return request('DELETE', APP_API_ROOT + processQuery(path, query, defaultQuery), data, logoutOn403, signal)
      },
      sendMagickLink(email: string, pwa = false) {
        return axios.post<{ email: string; pwa: boolean }, AxiosResponse<{ success: boolean }>>(
          processQuery(`${APP_API_ROOT}/auth/magiclink`, {}, defaultQuery),
          { email, pwa }
        )
      },
      loginWithMagicLink(email: string, secret: string) {
        const { r: redirectPath, marketing = '{}', s: marketingSourceProps } = queryString.parse(location.search)
        const marketingProps: Record<string, string> =
          marketing && typeof marketing === 'string' ? JSON.parse(marketing) : {}
        //Add url, referrer and source's marketing props to marketing props
        if (redirectPath && typeof redirectPath === 'string') marketingProps.url = redirectPath
        if (document.referrer) marketingProps.referrer = document.referrer

        return axios.post<{ email: string; pwa: boolean }, AxiosResponse<LoginResponse>>(
          processQuery(
            `${APP_API_ROOT}/auth/magiclink`,
            {
              marketing: JSON.stringify(marketingProps),
              marketingSource: typeof marketingSourceProps === 'string' ? marketingSourceProps : '',
            },
            defaultQuery
          ),
          { email, secret }
        )
      },
      uploadFilePath: '/file',
    }
  }, [lang])

  const contextValue: ApiContextType = {
    axios,
    appApi,
    setApiToken,
    ...i18nState,
  }

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
}

export function useApi(): ApiContextType {
  return useContext(ApiContext)
}
