import React from 'react'

interface CheckboxProps {
  inputLabel?: string | JSX.Element
  style?: string
  name: string
  value: boolean
  onValue?: boolean
  offValue?: boolean
  link?: { text: string; url: string }
  onChange: (data: { name: string; value: boolean }) => void
  disabled?: boolean
}

export default function Checkbox(props: CheckboxProps) {
  const {
    inputLabel: label = '',
    style,
    name,
    value,
    onChange,
    link,
    onValue = true,
    offValue = false,
    disabled = false,
  } = props

  const classes = ['form-checkbox']
  const isChecked = value === onValue

  if (isChecked) classes.push('form-checkbox--checked')

  const wrapperClasses = ['form-checkbox-wrapper']
  if (style) wrapperClasses.push(`form-checkbox-wrapper--${style}`)

  const labelClasses = ['form-checkbox-label']
  if (disabled) labelClasses.push('form-checkbox-label-disabled')
  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={classes.join(' ')}>
        <button
          className="form-checkbox-icon-wrapper"
          disabled={disabled}
          onClick={e => {
            e.preventDefault()
            onChange({ name, value: isChecked ? offValue : onValue })
          }}>
          <span className="form-checkbox-icon">{isChecked ? <i className="fa fa-check" /> : null}</span>
        </button>
        <div
          className={labelClasses.join(' ')}
          onClick={e => {
            if (disabled) return
            if (e.target instanceof HTMLDivElement && e.target.tagName === 'a') {
              return
            }
            onChange({ name, value: isChecked ? offValue : onValue })
          }}>
          {label}
          {link ? (
            <span className="form-checkbox-label-link">
              <a href={link.url} target="_BLANK" rel="noreferrer">
                {link.text}
              </a>
            </span>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  )
}
