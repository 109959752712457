import React, { Component, PureComponent, Fragment } from "react";
import { CountryDropdown } from "react-country-region-selector";
export default function CountryField({ name, id, onChange, value, t }) {
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--select" }, /* @__PURE__ */ React.createElement(
    CountryDropdown,
    {
      valueType: "short",
      value,
      id,
      defaultOptionLabel: t("common.selectCountry"),
      priorityOptions: ["FI", "SE", "US"],
      onChange: (value2) => onChange({ name, value: value2 })
    }
  ));
}
