import { UserData } from '@tm/types/common/app-api/auth'
import { WindowGlobals } from '../../window'

export const updateIntercom = (userData: UserData) => {
  // TODO: Do this typing cleanly
  const Intercom = (window as unknown as WindowGlobals).Intercom
  if (!Intercom) return

  try {
    const { email, name, contact_id } = userData

    const {
      name: company,
      id: organization_id,
      operating_company_id,
      appSubscriptionInfo: { plan_id } = {
        plan_id: undefined,
      },
    } = userData.organization || {}

    Intercom('boot', {
      app_id: 'skhk0scq',
      email,
      user_id: contact_id,
      user_name: name,
      company: {
        id: organization_id,
        name: company,
        plan: plan_id,
        operating_company_id,
      },
    })
  } catch (err) {
    console.log(err)
  }
}

export const resetIntercom = () => {
  const Intercom = (window as unknown as WindowGlobals).Intercom
  if (!Intercom) return
  Intercom('shutdown')
}
