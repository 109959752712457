var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { Component, Fragment, PureComponent } from "react";
import Helmet from "react-helmet";
import TranslationStore from "./i18n";
function MetaInfo({ lang }) {
  let rootTitle = "Trustmary Dashboard";
  if (lang === "se") lang = "sv";
  return /* @__PURE__ */ React.createElement(Helmet, { defaultTitle: rootTitle, titleTemplate: `%s - ${rootTitle}` }, /* @__PURE__ */ React.createElement("html", { lang }), /* @__PURE__ */ React.createElement("link", { rel: "icon", href: "https://d2nce6johdc51d.cloudfront.net/img/trustmary-favicon.png", sizes: "72x72" }));
}
export default function MetaInfoWrapper() {
  return /* @__PURE__ */ React.createElement(TranslationStore, null, (i18nState) => /* @__PURE__ */ React.createElement(MetaInfo, __spreadValues({}, i18nState)));
}
