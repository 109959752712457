import { Box, Button, ButtonProps } from '@mui/material'
import React from 'react'

interface SubmitProps {
  text?: string
  icon?: string
  disabled?: boolean
  classes?: string[]
  buttonClasses?: string[]
  children?: React.ReactNode
  size?: string
  color?: string
  align?: string
  iconPosition?: string
  useMui?: boolean
}

export const isMuiColor = (color?: string): color is ButtonProps['color'] => {
  return ['inherit', 'error', 'success', 'info', 'warning', 'primary', 'secondary'].includes(color || '')
}

export default function Submit(props: SubmitProps) {
  const {
    text = false,
    icon = false,
    disabled = false,
    classes = [],
    buttonClasses = [],
    children,
    size = 'default',
    color = 'blue',
    align = 'left',
    iconPosition = 'left',
    useMui = false,
  } = props

  if (useMui) {
    const muiColor = color && isMuiColor(color) ? color : 'secondary'

    return (
      <Box pb={2} px={2}>
        <Button
          disabled={disabled}
          fullWidth
          variant="contained"
          color={muiColor}
          type="submit"
          startIcon={icon && iconPosition === 'left' ? <i className={`fa fa-${icon}`} /> : null}
          endIcon={icon && iconPosition === 'right' ? <i className={`align-right fa fa-${icon}`} /> : null}>
          {text}
        </Button>
      </Box>
    )
  }
  return (
    <div className={`form-submit form-submit--${size} form-submit--${align} ${classes.join(' ')}`}>
      <div className="form-submit-button">
        <button
          className={`button button--${size} button--${color} ${buttonClasses.join(' ')}`}
          type="submit"
          disabled={disabled}>
          {icon && iconPosition === 'left' ? <i className={`fa fa-${icon}`} /> : null}
          {text}
          {icon && iconPosition === 'right' ? <i className={`align-right fa fa-${icon}`} /> : null}
        </button>
      </div>
      <div className="form-submit-content">{children}</div>
    </div>
  )
}
