import React, { useCallback } from 'react'
import { Translator } from '../../i18n'
import { Modal, ModalContent, ModalHeader } from '../modal'
import './confirmDialog.sass'

const ConfirmButton = (props: { label: string; preferred: boolean; onClick: React.MouseEventHandler }) => {
  const style = props.preferred ? '' : 'button--white'
  return (
    <button className={`button button--small ${style}`} onClick={props.onClick} data-testid={props.label}>
      {props.label}
    </button>
  )
}

export const ConfirmDialog = (props: {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  title: string
  description: string
  variant?: 'yes-preferred' | 'no-preferred'
  t: Translator
}) => {
  const { t, title, description, onConfirm, onCancel, open, variant = 'yes-preferred' } = props
  const handleConfirm: React.MouseEventHandler = useCallback(
    e => {
      e.preventDefault()
      onConfirm()
    },
    [onConfirm]
  )
  const handleCancel: React.MouseEventHandler = useCallback(
    e => {
      e.preventDefault()
      onCancel()
    },
    [onCancel]
  )

  if (!open) {
    return null
  }

  return (
    <Modal size="tiny">
      <ModalHeader title={title} description={description} icon="" onClose={onCancel} />
      <ModalContent>
        <div className="modal-content-action confirm-modal-buttons" data-testid="confirm-dialog-buttons">
          <div className="form-cancel-button">
            <ConfirmButton
              label={t('dialog.confirm.no')}
              preferred={variant !== 'yes-preferred'}
              onClick={handleCancel}
            />
          </div>
          <div className="form-submit-button">
            <ConfirmButton
              label={t('dialog.confirm.yes')}
              preferred={variant === 'yes-preferred'}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
