import validator from "validator";
export default function Validator(values, rules = {}, t) {
  if (typeof t !== "function") t = (key) => key;
  let errors = {};
  let setError = (name, error) => {
    errors[name] = errors[name] || [];
    if (errors[name].indexOf(error) === -1) errors[name].push(error);
  };
  for (let name in rules) {
    let ruleSet = rules[name];
    let namePath = name.split(".");
    let value = values.getIn(namePath, "");
    for (let rule of ruleSet) {
      let parts = rule.split(":");
      switch (parts[0]) {
        case "required":
          if (!value) setError(name, t("validation.required"));
          break;
        case "requiredGroup":
          if (!value) setError(name, t("validation.requiredGroup"));
          break;
        case "not":
          let items = parts[1].split(",");
          if (items.length && items.indexOf(value) !== -1) setError(name, t("validation.taken"));
          break;
        case "min":
          if (value && value.length < parts[1])
            setError(name, t("validation.min", "Arvon pit\xE4\xE4 olla v\xE4hint\xE4\xE4n :0 pitk\xE4", [parts[1]]));
          break;
        case "max":
          if (value && value.length > parts[1])
            setError(name, t("validation.max", "Arvon voi olla maksimissaan :0 pitk\xE4", [parts[1]]));
          break;
        case "email":
          if (value && !validator.isEmail(value)) setError(name, t("validation.email"));
          break;
        case "date":
          if (value && !validator.isDate(value)) setError(name, t("validation.date"));
          break;
        case "same":
          if (value !== values.get(parts[1]))
            setError(name, t("validation.same", "Arvon t\xE4ytyy olla sama kuin :0", [parts[1]]));
          break;
        case "deleteConfirm":
          if (value !== (parts[1] || "DELETE"))
            setError(name, `${t("validation.deleteConfirm")} ${parts[1] || "DELETE"}`);
          break;
        case "token":
          if (value && value.indexOf(parts[1]) === -1) setError(name, `${t("validation.tokenMissing")} ${parts[1]}`);
          break;
        case "url":
          if (value && !validator.isURL(value)) setError(name, t("validation.url"));
          break;
      }
    }
  }
  return errors;
}
