import React from 'react'

// Note: This setups the global uncaught exception capture (the exceptionst ErrorBoundary will NOT catch)
import { ErrorMessage } from './ErrorMessage'
import { catchError, setupErrorReceiver } from './setupErrorCapture'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean; message?: string }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
    setupErrorReceiver(this.errorReceiver)
  }

  componentDidCatch(error: Error) {
    // Make sure the error is captured by Sentry etc.
    catchError(error)
  }

  static getDerivedStateFromError(error: Error) {
    // specify that the error boundary has caught an error
    return {
      hasError: true,
      message: error.message,
    }
  }

  /** This might be called multiple times for the same error so beware */
  errorReceiver = (error: Error) => {
    this.setState(ErrorBoundary.getDerivedStateFromError(error))
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage title="Something went wrong" details={this.state.message} />
    }
    return this.props.children
  }
}
