import { useApi } from '@tm/client-app/src/api'
import { Achievement, Goal, Organization } from '@tm/types/db'
import { OrganizationGoals } from '@tm/types/org-goal-tracking'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../auth'

export const OrgGoalTrackingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [goalsLoading, setGoalsLoading] = useState(false)
  const [goals, setGoals] = useState<OrganizationGoals>({ achievements: [], nextGoal: null })
  const [error, setError] = useState<string>()
  const { appApi } = useApi()
  const { loggedIn, organization } = useAuth()
  const location = useLocation()

  useEffect(() => {
    if (!loggedIn) return
    setGoalsLoading(true)
    const abortController = new AbortController()
    appApi
      .get<OrganizationGoals>(
        `/goal-tracking/achievements/${organization.id}`,
        undefined,
        undefined,
        abortController.signal
      )
      .then(response => {
        setGoals(sortByGoalNumber(response.data))
      })
      .catch((err: Error) => {
        if (!abortController.signal.aborted) setError(err.message)
      })
      .finally(() => {
        if (!abortController.signal.aborted) setGoalsLoading(false)
      })
    return () => abortController.abort()
  }, [appApi, loggedIn, organization.id, location.pathname])

  const setAchievementStatus = useCallback(
    (organizationId: Organization['id'], goalId: Goal['id'], status: Achievement['status']) => {
      setGoalsLoading(true)
      appApi
        .put<OrganizationGoals>(
          `/goal-tracking/achievements/${organizationId}/${goalId}`,
          { status },
          undefined,
          undefined
        )
        .then(response => {
          setGoals(sortByGoalNumber(response.data))
        })
        .catch((err: Error) => {
          setError(err.message)
        })
        .finally(() => {
          setGoalsLoading(false)
        })
    },
    [appApi]
  )

  const skipGoal = useCallback(
    (organizationId: Organization['id'], goalId?: Goal['id']) => {
      if (!goalId) return
      setAchievementStatus(organizationId, goalId, 'skipped')
    },
    [setAchievementStatus]
  )

  const unskipGoal = useCallback(
    (organizationId: Organization['id'], goalId?: Goal['id']) => {
      if (!goalId) return
      setAchievementStatus(organizationId, goalId, 'not_done')
    },
    [setAchievementStatus]
  )

  const value = useMemo(
    () => ({
      isLoading: goalsLoading,
      achievements: goals.achievements,
      error,
      nextGoal: goals.nextGoal,
      skipGoal,
      unskipGoal,
    }),
    [goalsLoading, goals.achievements, goals.nextGoal, error, skipGoal, unskipGoal]
  )

  return <OrgGoalTrackingContext.Provider value={value}>{children}</OrgGoalTrackingContext.Provider>
}

function sortByGoalNumber(data: OrganizationGoals) {
  return {
    achievements: data.achievements.sort((a, b) => a.order - b.order),
    nextGoal: data.nextGoal,
  }
}

const OrgGoalTrackingContext = createContext<
  OrganizationGoals & {
    isLoading: boolean
    error: string | undefined
    nextGoal: string | null
    skipGoal: (organizationId: Organization['id'], goalId?: Goal['id']) => void
    unskipGoal: (organizationId: Organization['id'], goalId?: Goal['id']) => void
  }
>({} as never)

export const useOrgGoalTracking = () => useContext(OrgGoalTrackingContext)
