var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { fromJS, List, Map } from "immutable";
import React, { Component } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { generate as genId } from "shortid";
const Handle = SortableHandle((props) => /* @__PURE__ */ React.createElement("div", { className: "options-handle" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-bars" })));
const OptionItem = SortableElement(({ option, onChange, onKeyDown, lang, fieldIndex: index, id, mainLang, t }) => {
  let label = option.get("label", Map());
  let value = option.get("value");
  return /* @__PURE__ */ React.createElement("div", { className: "options-item" }, /* @__PURE__ */ React.createElement("div", { className: "options-item-handle" }, /* @__PURE__ */ React.createElement(Handle, null)), /* @__PURE__ */ React.createElement("div", { className: "options-item-input" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "text",
      name: `${index}.label.${lang}`,
      id: `${id}-${value}`,
      value: label.get(lang, ""),
      placeholder: label.get(mainLang || "en", ""),
      onChange,
      onKeyDown: (e) => onKeyDown(e, index)
    }
  )));
});
const OptionList = SortableContainer(({ options, onChange, onKeyDown, id, lang, mainLang, t }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "options-list" }, options.map((option, index) => /* @__PURE__ */ React.createElement(
    OptionItem,
    {
      key: option.get("value"),
      fieldIndex: index,
      index,
      option,
      id,
      lang,
      mainLang,
      onChange,
      onKeyDown,
      t
    }
  )));
});
export default class OptionsField extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "handleOptionChange", (e) => {
      let { name: inputName, value: inputValue } = e.target || e;
      let { value, name, onChange } = this.props;
      if (!List.isList(value)) value = List();
      onChange({
        name,
        value: value.setIn(inputName.split("."), inputValue)
      });
    });
    __publicField(this, "handleCreateChange", (e, index = -1) => {
      let { value: inputValue = "" } = e.target || e;
      let { id } = this.state;
      if (e.target) e.target.value = "";
      let { value, name, lang = "en", onChange } = this.props;
      let optionId = genId();
      if (!List.isList(value)) value = List();
      let label = {};
      label[lang] = inputValue;
      let option = fromJS({
        value: optionId,
        label
      });
      if (index < 0) {
        value = value.push(option);
      } else {
        value = value.insert(index, option);
      }
      onChange(
        {
          name,
          value
        },
        () => {
          let input = this._parent.querySelector(`#${id}-${optionId}`);
          if (input) input.focus();
        }
      );
    });
    __publicField(this, "removeOption", (index) => {
      let { value, name, onChange } = this.props;
      let { id } = this.state;
      onChange(
        {
          name,
          value: value.remove(index)
        },
        () => {
          if (index === 0) return;
          let optionId = value.getIn([index - 1, "value"]);
          let input = this._parent.querySelector(`#${id}-${optionId}`);
          if (input) input.focus();
        }
      );
    });
    __publicField(this, "handleOptionKeyDown", (e, index) => {
      if (e.key === "Backspace" && e.target.value === "") {
        e.preventDefault();
        this.removeOption(index);
      }
      if (e.key === "Enter") {
        e.preventDefault();
        this.handleCreateChange({}, index + 1);
      }
    });
    __publicField(this, "handleReorder", ({ oldIndex, newIndex }) => {
      let { value, name, lang = "en", onChange } = this.props;
      let source = value.get(oldIndex);
      value = value.setIn([oldIndex, "_delete"], true).insert(oldIndex > newIndex ? newIndex : newIndex + 1, source).filter((s) => s.get("_delete", false) === false);
      onChange({ name, value });
    });
    this.state = {
      id: props.name.replace(/\./g, "")
    };
  }
  render() {
    let { value, onChange, name, lang = "en", mainLang = "en", t } = this.props;
    let { id } = this.state;
    if (!List.isList(value)) value = List();
    let createId = `options-create-${id}`;
    return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--options", ref: (el) => this._parent = el }, /* @__PURE__ */ React.createElement("div", { className: "options" }, /* @__PURE__ */ React.createElement(
      OptionList,
      {
        options: value,
        onChange: this.handleOptionChange,
        onKeyDown: this.handleOptionKeyDown,
        onSortEnd: this.handleReorder,
        name,
        lang,
        mainLang,
        id,
        useDragHandle: true,
        t
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "options-item options-item--create" }, /* @__PURE__ */ React.createElement("label", { className: "options-item-handle", htmlFor: createId }, /* @__PURE__ */ React.createElement("div", { className: "options-handle" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-plus" }))), /* @__PURE__ */ React.createElement("div", { className: "options-item-input" }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        id: createId,
        placeholder: t("common.createOption"),
        onChange: this.handleCreateChange
      }
    )))));
  }
}
