import { faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Box, Button, Stack, TextField } from '@mui/material'
import { FontAwesomeSvgIcon } from '@tm/ui/composite/FontAwesomeSvgIcon'
import { List, Map, fromJS } from 'immutable'
import React, { useCallback, useEffect, useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { generate as genId } from 'shortid'
import { Translator } from '../i18n'

const Handle = SortableHandle(() => (
  <div className="options-handle">
    <i className="fa fa-bars" />
  </div>
))

interface LinkItemProps {
  link: Map<unknown, unknown>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: (linkId: string) => void
  lang: string
  fieldIndex: number
  t: Translator
}

const LinkItem = SortableElement(({ link, onChange, onDelete, lang, fieldIndex: index, t }: LinkItemProps) => {
  const label = link.get('label', Map()) as Map<string, string>
  const url = link.get('url', Map()) as string

  const onDeleteClick = () => {
    onDelete(link.get('value', '') as string)
  }

  return (
    <Stack padding={1} gap={1} direction="row" className="options-item">
      <Box className="options-item-handle">
        <Handle />
      </Box>
      <Stack gap={1} className="options-item-input">
        <TextField
          type="text"
          name={`${index}.label.${lang}`}
          value={label.get(lang, '')}
          label={t('form.editor.link.label')}
          onChange={onChange}
        />
        <TextField
          type="text"
          name={`${index}.url`}
          value={url}
          label={t('form.editor.link.url')}
          onChange={onChange}
        />
      </Stack>
      <Stack justifyContent="space-around">
        <Button variant="outlined" onClick={onDeleteClick}>
          <FontAwesomeSvgIcon icon={faTrash} />
        </Button>
      </Stack>
    </Stack>
  )
})

interface LinkListProps {
  name: string
  links: List<Map<string, string>>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: (linkId: string) => void
  lang: string
  t: Translator
}

const LinkList = SortableContainer(({ links, onChange, onDelete, lang, t }: LinkListProps) => {
  return (
    <div className="options-list">
      {links &&
        links.map((link, index) => (
          <LinkItem
            key={link.get('value')}
            fieldIndex={index}
            index={index}
            link={link}
            lang={lang}
            onChange={onChange}
            onDelete={onDelete}
            t={t}
          />
        ))}
    </div>
  )
})

interface LinksFieldState {
  name: string
  value: List<Map<string, any>>
  inputValue: string
  lang: string
  t: Translator
}

type LinksFieldProps = LinksFieldState & {
  onChange?: ({ name, value }: { name: string; value: List<Map<string, any>> }, callback?: () => void) => void
}

const LinksField = ({ name, onChange, value: extValue, lang, t }: LinksFieldProps) => {
  const [value, setValue] = useState<List<Map<string, any>>>(List())
  const [label, setLabel] = useState('')
  const urlPlaceholder = 'https://'
  const [url, setUrl] = useState(urlPlaceholder)

  useEffect(() => {
    setValue(extValue || List())
  }, [extValue])

  const handleLinkChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name: inputName, value: inputValue } = e.target || e
      if (!List.isList(value)) setValue(List())
      const newValue = value.setIn(inputName.split('.'), inputValue)
      onChange &&
        onChange({
          name,
          value: newValue,
        })
    },
    [name, onChange, value]
  )

  const handleReorder = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const source = value.get(oldIndex, Map<string, string>())

      const newValue = value
        .setIn([oldIndex, '_delete'], true) // Flag source to be deleted
        .insert(oldIndex > newIndex ? newIndex : newIndex + 1, source)
        .filter((s: Map<string, string>) => s.get('_delete', false) === false) // Remove item with _delete flag

      onChange && onChange({ name, value: newValue })
    },
    [name, onChange, value]
  )

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLabel(event.target.value)
  }

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUrl(event.target.value)
  }

  const onConfirmClick = () => {
    const newId = genId()
    const langLabel = {}
    langLabel[lang] = label

    const link = fromJS({
      value: newId,
      label: langLabel,
      url,
    })

    onChange &&
      onChange({
        name,
        value: value.push(link),
      })

    setLabel('')
    setUrl('https://')
  }

  const onDeleteClick = (linkId: string) => {
    console.log('delete', linkId)
    onChange && onChange({ name, value: value.filter(s => s.get('value', linkId) !== linkId) })
  }

  return (
    <div className="form-field form-field--options">
      <LinkList
        links={value}
        onChange={handleLinkChange}
        onDelete={onDeleteClick}
        onSortEnd={handleReorder}
        name={name}
        lang={lang}
        useDragHandle
        t={t}
      />
      <Stack padding={1} gap={1} direction="row" sx={{ border: '1px solid #e6e8eb', borderRadius: '4px' }}>
        <Stack justifyContent="center">
          <FontAwesomeSvgIcon icon={faPlus} />
        </Stack>
        <Stack gap={1} sx={{ flex: 1 }}>
          <TextField size="small" label={t('form.editor.link.label')} value={label} onChange={handleLabelChange} />
          <TextField size="small" label={t('form.editor.link.url')} value={url} onChange={handleUrlChange} />
        </Stack>
        <Stack justifyContent="space-around">
          <Button variant="outlined" disabled={!label || !url || urlPlaceholder.includes(url)} onClick={onConfirmClick}>
            <FontAwesomeSvgIcon icon={faCheck} />
          </Button>
        </Stack>
      </Stack>
    </div>
  )
}

export default LinksField
