import React from "react";
import Datepicker from "react-datetime";
import * as moment from "moment";
import { useState } from "react";
import { useCallback } from "react";
import "./datetime.sass";
function DatepickerInput(props) {
  const { inputProps, openCalendar, closeCalendar, date } = props;
  const [open, setOpen] = useState(false);
  const hoursOffset = date.utcOffset() / 60;
  const onInputClick = useCallback(
    (e) => {
      e.preventDefault();
      open ? closeCalendar() : openCalendar();
      setOpen(!open);
    },
    [open]
  );
  return /* @__PURE__ */ React.createElement("button", { className: "button button--small button--grey", onClick: onInputClick }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-calendar" }), inputProps.value, " (UTC ", hoursOffset < 0 ? hoursOffset : "+" + hoursOffset, ")");
}
export default function TimeofdayField(props) {
  let { onChange, value, name, t, valueResolution = "minutes" } = props;
  const date = typeof value === "number" ? moment.utc().startOf("day").add(value, valueResolution).local() : moment();
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--datetime" }, /* @__PURE__ */ React.createElement(
    Datepicker,
    {
      className: "datetime-datepicker",
      timeFormat: true,
      input: true,
      renderInput: (inputProps, openCalendar, closeCalendar) => /* @__PURE__ */ React.createElement(
        DatepickerInput,
        {
          inputProps,
          openCalendar,
          closeCalendar,
          date
        }
      ),
      value: date,
      onChange: (e) => {
        if (typeof e === "string") {
        } else {
          const startOfDay = e.clone().utc().startOf("day");
          const minuteOfDay = e.utc().diff(startOfDay, valueResolution);
          onChange({
            name,
            value: minuteOfDay
          });
        }
      },
      dateFormat: false
    }
  ));
}
