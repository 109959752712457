import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Map, List, fromJS } from "immutable";
import Radios from "./radios";
function EmbedPreview({ theme }) {
  let elRef = useRef();
  let embedRef = useRef();
  useLayoutEffect(() => {
    if (!embedRef.current) embedRef.current = TrustmaryEmbed.initPreview(elRef.current, theme.toJS());
  });
  return /* @__PURE__ */ React.createElement("div", { className: "templates-embed-preview", ref: elRef });
}
export default function EmbedTheme(props) {
  let {
    value,
    name,
    onChange,
    loading = false,
    templates,
    templateSourceField = "templateSource",
    values,
    lang,
    templateSourceOptions,
    t
  } = props;
  let source = values.get(templateSourceField);
  let [templateOptions, setTemplateOptions] = useState(List());
  let prevConfig = useRef();
  useEffect(() => {
    let sourceLoading = source + loading.toString();
    if (prevConfig.current !== sourceLoading) {
      let items = templates.get(source, List());
      setTemplateOptions(items);
      if (items.size) onChange({ name, value: items.getIn([0, "id"]) });
    }
    prevConfig.current = sourceLoading;
  });
  return /* @__PURE__ */ React.createElement("div", { className: "templates templates--embed" }, /* @__PURE__ */ React.createElement("div", { className: "templates-source" }, /* @__PURE__ */ React.createElement(Radios, { name: templateSourceField, value: source, onChange, options: templateSourceOptions })), /* @__PURE__ */ React.createElement("div", { className: "templates-list" }, !List.isList(templateOptions) || !templateOptions.size ? /* @__PURE__ */ React.createElement("div", { className: "templates-empty" }, t("embedCreateWizard.template.emptyList")) : templateOptions.map((template) => {
    let id = template.get("id");
    let isSelected = id === value;
    let title = template.getIn(["info", lang, "title"], template.getIn(["info", "en", "title"]));
    let classes = ["templates-embed"];
    if (isSelected) classes.push("templates-embed--selected");
    return /* @__PURE__ */ React.createElement("div", { className: "templates-list-item", key: id }, /* @__PURE__ */ React.createElement("button", { className: classes.join(" "), onClick: (e) => e.preventDefault() & onChange({ name, value: id }) }, /* @__PURE__ */ React.createElement(EmbedPreview, { theme: template.get("theme", Map()) }), /* @__PURE__ */ React.createElement("div", { className: "templates-embed-title-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "templates-embed-title" }, /* @__PURE__ */ React.createElement("div", { className: "templates-embed-title-check" }, /* @__PURE__ */ React.createElement("span", { className: "form-checkbox-icon-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: `form-checkbox-icon ${isSelected ? "form-checkbox-icon--checked" : ""}` }, isSelected ? /* @__PURE__ */ React.createElement("i", { className: "fa fa-check" }) : null))), /* @__PURE__ */ React.createElement("div", { className: "templates-embed-title-name" }, /* @__PURE__ */ React.createElement("span", { className: "ellipsis" }, title || t("embedCreateWizard.emptyName")))))));
  })));
}
