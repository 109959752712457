import {
  BillingAddress,
  PatchTaxInfoBody,
  PatchTaxInfoResponse,
  TaxInfoFields,
} from '@tm/server-billing/routes/patchTaxInfoRoute'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../auth'
import { useBilling } from './hook/useBilling'

const VATInfoContext = createContext<{
  isLoading: boolean
  /** Info hasn't been set by the customer */
  isEmpty: boolean

  /** VAT ID with the country prefix, e.g. FI1234567 */
  address: string
  city: string
  state: string
  country: string
  companyName: string
  postcode: string
  vatId: string

  updateVATNumber: (info: TaxInfoFields) => Promise<
    | PatchTaxInfoResponse
    | {
        success: boolean
        errorCode: string
      }
  >
}>({} as never)

export const VATInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const { appApi } = useAuth()
  const { billing, billingAddress, loading } = useBilling()
  const vatInfo = (billing.toJS().vatInfo as { vatNumber: string; countryCode: string }) || null
  const [fullVatId, setFullVatId] = useState<string>()
  const [address, setAddress] = useState<BillingAddress>()

  useEffect(() => {
    if (!loading) {
      // Only initialize when fullVatId is undefined to avoid overwriting with the old data
      if (fullVatId === undefined) setFullVatId(`${vatInfo?.countryCode || ''}${vatInfo?.vatNumber || ''}`)
      // Only initialize when address is undefined to avoid overwriting withthe old data
      if (address === undefined && billingAddress) setAddress(billingAddress)
    }
  }, [loading, vatInfo?.countryCode, vatInfo?.vatNumber, billingAddress, vatInfo, address, fullVatId])

  const updateVATNumber = useCallback(
    async (info: TaxInfoFields) => {
      try {
        const { data } = await appApi.patch<PatchTaxInfoResponse, PatchTaxInfoBody>('/billing/tax-info', info)

        if (data.success) {
          setFullVatId(info.vatId)
          setAddress(info)
        }

        return data
      } catch (error) {
        return { success: false, errorCode: 'error' }
      }
    },
    [appApi]
  )

  const value = useMemo(
    () => ({
      updateVATNumber,
      vatId: fullVatId || '',
      address: address?.address || '',
      postcode: address?.postcode || '',
      companyName: address?.companyName || '',
      country: address?.country || '',
      state: address?.state || '',
      city: address?.city || '',
      isLoading: loading,
      isEmpty: !address?.country,
    }),
    [
      fullVatId,
      address?.address,
      address?.city,
      address?.companyName,
      address?.country,
      address?.postcode,
      address?.state,
      loading,
      updateVATNumber,
    ]
  )

  return <VATInfoContext.Provider value={value}>{children}</VATInfoContext.Provider>
}

export const useVATInfo = () => useContext(VATInfoContext)
