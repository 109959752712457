import React, { Component, PureComponent, Fragment } from "react";
export default function Fieldset(props) {
  let { title, folded = false, table = false, box = false, columns = 1, children, color = false, icon = false } = props;
  let classes = ["form-fieldset"];
  if (folded) classes.push("form-fieldset--folded");
  if (table) classes.push("form-fieldset--table");
  if (box) classes.push("form-fieldset--box");
  if (parseInt(columns) > 1) classes.push(`form-fieldset--cols-${columns}`);
  if (color) classes.push(`form-fieldset--${color}`);
  return /* @__PURE__ */ React.createElement("div", { className: classes.join(" ") }, title ? /* @__PURE__ */ React.createElement("div", { className: "form-fieldset-title" }, /* @__PURE__ */ React.createElement("div", { className: "form-fieldset-title-inner" }, icon ? /* @__PURE__ */ React.createElement("i", { className: `fa fa-${icon}` }) : null, title)) : null, /* @__PURE__ */ React.createElement("div", { className: "form-fieldset-fields" }, children));
}
