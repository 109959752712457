import { OrganizationFlag } from '@tm/server-embed/api/aggEvent/events/util/checkAndSetFlag'
import {
  AcceptTermBody,
  AcceptTermResult,
  AuthBody,
  AuthResult,
  Feature,
  Organization,
  UserData,
} from '@tm/types/common/app-api/auth'
import { AppLimits } from '@tm/types/db/tables'
import { History, Location } from 'history'
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ApiContextType, useApi } from '../api'
import { LOCAL_ORGANIZATION_KEY, request } from '../api/request'
import PageLoader from '../common/pageLoader'
import { useI18n } from '../i18n'

import AuthErrorPage from './errorPage'
import { useAuthLoadEvent } from './hook/useAuthLoadEvent'
import { resetCello, updateCello } from './integration/cello'
import { updateHotjar } from './integration/hotjar'
import { resetIntercom, updateIntercom } from './integration/intercom'
import { resetMixpanel, updateMixpanel } from './integration/mixpanel'
import { resetTawk, updateTawk } from './integration/tawk'
import TermOverlay from './termOverlay'

function checkIfPwa() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (
    (window.navigator as Navigator & { standalone: boolean }).standalone == true ||
    window.matchMedia('(display-mode: standalone)').matches
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObject = (obj: any): obj is Record<string, unknown> => typeof obj === 'object'

const getIn = (obj: Record<string, unknown>, keys: string[]): Record<string, unknown> | unknown => {
  if (!Array.isArray(keys)) return

  const [key, ...rest] = keys
  const value = obj[key]

  if (typeof value === 'undefined') return

  if (rest.length) {
    if (!isObject(value)) return

    return getIn(value, rest)
  }

  return value
}

// TODO: Rewrite to use a suitable interface
// eslint-disable-next-line @typescript-eslint/ban-types
function createMap<R>(obj: {} | undefined | null): R {
  if (typeof obj !== 'object' || obj == null) obj = {}

  if (isObject(obj)) {
    // This will always be the case
    obj.get = function (key: string, defaultValue: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return (this as Record<string, unknown>)[key] || defaultValue
    }

    obj.getIn = function (keys: string[], defaultValue: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return getIn(this, keys) || defaultValue
    }
  }

  return obj as R
}

type AppLimitKey = keyof AppLimits<number> | keyof NonNullable<AppLimits<number>['limits']>

export interface AuthContextType extends ApiContextType {
  history: History
  location: Location
  loggedIn: boolean | undefined
  logout: () => Promise<void>
  // TODO: undefined userData needs to be handled by the consumers - this is just to avoid crashes
  // eslint-disable-next-line @typescript-eslint/ban-types
  userData: UserData
  authLoading: boolean
  organization: Organization
  pwa: boolean
  isFreemiumUser: boolean
  updateToken: () => Promise<void>
  changeOrganization: (org: string, redirect?: string) => Promise<void>
  hasFeature: (feature: Feature | Feature[]) => boolean
  getLimit: (limit: AppLimitKey) => number
  testLimit: (limit: keyof AppLimits<number>, count: string | number) => boolean
  toggleFeature: (feature: Feature) => void
  loadAuthData: (organization_id?: string | false, redirect?: string) => Promise<void>
  setOrganizationFlag: (flag: OrganizationFlag) => Promise<void>
  createMissingOrganization: () => Promise<void>
}

/* Store for auth */
export const AuthContext = createContext<AuthContextType>({} as never)
export const AuthStore = AuthContext.Consumer

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { setLanguage, lang } = useI18n()
  const apiState = useApi()
  const { appApi, setApiToken, t } = apiState
  const history = useHistory()
  const location = useLocation()

  const loaded = useRef(false)

  // TODO: This initialization should be full in case e.g. the flags map
  // is used somewhere before this context has loaded properly
  const [userData, setUserData] = useState<UserData>({
    orgFeatures: [],
    organization: { flags: {} },
  } as never)

  const [authLoading, setAuthLoading] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [loggedIn, setLoggedIn] = useState<boolean>()

  /* Accept term management */
  const [acceptTermAction, setAcceptTermAction] = useState(false)
  const [acceptTermLoading, setAcceptTermLoading] = useState(false)

  const logout = useCallback(async () => {
    await request('POST', '/api/auth/signout')
    setApiToken(false)
    setLoggedIn(false)
    setUserData({} as never)
    resetIntercom()
    resetTawk()
    resetMixpanel()
    resetCello()
    history.push('/login')
  }, [history, setApiToken])

  const loadAuthData = useCallback(
    (set_organization: string | false = false, redirect?: string) => {
      return request<AuthResult, AuthBody>('POST', '/api/auth', { set_organization })
        .then(res => res.data)
        .then(res => {
          setAuthLoading(false)

          const { isLoggedIn, token, userData, organization, acceptTermAction } = res

          userData.organization = createMap<Organization>(organization)
          sessionStorage.setItem(LOCAL_ORGANIZATION_KEY, organization?.id || '')

          setUserData(userData)
          setApiToken(token)
          setAcceptTermAction(acceptTermAction)
          setLoggedIn(isLoggedIn)
          if (userData.lang && userData.lang !== lang) setLanguage(userData.lang)

          if (userData && userData.email) {
            updateMixpanel(userData)
            updateIntercom(userData)
            updateTawk(userData)
            updateCello(userData)
            updateHotjar(userData)
          }

          setAuthLoading(false)

          if (loaded.current) {
            const { pathname } = location
            if (redirect) history.push(redirect)
            else if (pathname.startsWith('/contact/list/')) history.push('/contact')
            else if (pathname.startsWith('/survey/')) history.push('/survey')
            else if (pathname.startsWith('/embed/')) history.push('/embed')
          }

          // Save initial load
          loaded.current = true
        })
        .catch(err => {
          console.log(err)
          if (location.pathname === '/logout') {
            void logout()
          } else {
            setAuthError(true)
          }
        })
    },
    [history, lang, location, logout, setApiToken, setLanguage]
  )

  const createMissingOrganization = useCallback(async () => {
    try {
      const organization = await request<{ organizationId: string }>('POST', '/api/auth/create-missing-organization')
      await loadAuthData(organization.data.organizationId)
    } catch (err) {
      console.log(err)
    }
  }, [loadAuthData])

  useEffect(() => {
    setAuthLoading(true)
    void loadAuthData()
  }, [])

  const updateToken = () => {
    setAuthLoading(true)
    return loadAuthData()
  }

  const changeOrganization = (organization_id: string, redirect?: string) => {
    setAuthLoading(true)
    return loadAuthData(organization_id, redirect)
  }

  const acceptTerm = (term_id: string) => {
    if (acceptTermLoading) return

    setAcceptTermLoading(true)
    return appApi.post<AcceptTermResult, AcceptTermBody>('/auth/accept-term', { term_id }).then(({ data }) => {
      setAcceptTermLoading(false)
      setAcceptTermAction(data.success ? false : acceptTermAction)
    })
  }

  const hasFeature = (feature: Feature | Feature[]): boolean => {
    if (!userData) return false
    const { orgFeatures } = userData

    if (typeof feature === 'object') return feature.some(feature => hasFeature(feature))

    return orgFeatures.includes(feature)
  }

  const getLimit = (key: string) => {
    if (!userData) return 0
    const { appSubscriptionInfo = { limits: {} } } = userData.organization
    const { limits = {} } = appSubscriptionInfo
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const value = parseInt(appSubscriptionInfo[key] || limits[key] || 0)
    return isNaN(value) ? 0 : value
  }

  const testLimit = (key: string, count: string | number) => {
    const testValue = getLimit(key)
    if (testValue === -1) return true
    const numberCount = typeof count === 'number' ? count : parseInt(count)
    return numberCount < testValue
  }

  const toggleFeature = (feature: Feature) => {
    if (!userData) return
    let { orgFeatures } = userData
    orgFeatures = orgFeatures.includes(feature) ? orgFeatures.filter(f => f !== feature) : [...orgFeatures, feature]
    setUserData({ ...userData, orgFeatures })
  }

  const setOrganizationFlag = useCallback(
    async (flag: OrganizationFlag) => {
      await appApi.post<{ flag: OrganizationFlag }>('/auth/organization/flag', { flag })
    },
    [appApi]
  )

  useAuthLoadEvent(userData)

  if (authLoading && location.pathname !== '/sso/google' && location.pathname !== '/sso/microsoft') return authError ? <AuthErrorPage /> : <PageLoader />

  const contextValue: AuthContextType = {
    history,
    location,
    loggedIn,
    logout,
    userData,
    authLoading,
    organization: userData?.organization || createMap({}),
    pwa: checkIfPwa(),
    updateToken,
    changeOrganization,
    hasFeature,
    getLimit,
    testLimit,
    toggleFeature,
    setOrganizationFlag,
    isFreemiumUser: userData?.organization?.hasFreePlan || false,
    loadAuthData,
    createMissingOrganization,
    ...apiState,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {authError && (
        <div className="auth-error">
          <div className="message message--error">{t('error.auth')}</div>
        </div>
      )}
      {children}
      {acceptTermAction && (
        <TermOverlay
          acceptTermAction={acceptTermAction}
          acceptTerm={acceptTerm}
          acceptTermLoading={acceptTermLoading}
          t={t}
        />
      )}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
