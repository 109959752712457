import { List } from "immutable";
import React from "react";
import "./repeater.sass";
const utm_keys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
function UtmInput({ value, onChange, disabled }) {
  let index = value.indexOf("=");
  let utm_key = value.substr(0, index);
  let utm_value = value.substr(index + 1);
  return /* @__PURE__ */ React.createElement("div", { className: "form-repeater-utm" }, /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--select" }, /* @__PURE__ */ React.createElement(
    "select",
    {
      onChange: (e) => onChange(`${e.target.value}=${utm_value}`),
      value: utm_key,
      disabled,
      "data-testid": "utm-key"
    },
    /* @__PURE__ */ React.createElement("option", { value: "" }, "-"),
    utm_keys.map((utm_key2) => /* @__PURE__ */ React.createElement("option", { key: utm_key2, value: utm_key2 }, utm_key2))
  )), /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "text",
      value: utm_value,
      onChange: (e) => onChange(`${utm_key}=${e.target.value}`),
      disabled,
      "data-testid": "repeater-text-input"
    }
  ));
}
export default function RepeaterInput(props) {
  let { value, name, onChange, t, repeaterType = "text", disabled = false } = props;
  if (!List.isList(value)) value = List().push("");
  const updateItem = (key, inputValue) => onChange({
    name,
    value: value.set(key, inputValue)
  });
  const removeItem = (key) => onChange({
    name,
    value: value.remove(key)
  });
  const addItem = () => onChange({
    name,
    value: value.push("")
  });
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--repeater" }, /* @__PURE__ */ React.createElement("div", { className: "form-repeater-fields" }, value.map((inputValue, key) => {
    return /* @__PURE__ */ React.createElement("div", { className: "form-repeater-field", key }, repeaterType === "utm" ? /* @__PURE__ */ React.createElement(
      UtmInput,
      {
        value: inputValue,
        onChange: (updatedValue) => updateItem(key, updatedValue),
        disabled
      }
    ) : /* @__PURE__ */ React.createElement(
      "input",
      {
        "data-testid": "repeater-input-field",
        type: "text",
        value: inputValue,
        onChange: (e) => updateItem(key, e.target.value),
        disabled
      }
    ), /* @__PURE__ */ React.createElement("div", { className: "form-repeater-field-remove" }, !disabled ? /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & removeItem(key), "data-testid": "remove-button" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-trash" })) : /* @__PURE__ */ React.createElement("div", { className: "no-button-placeholder" })));
  })), !disabled && /* @__PURE__ */ React.createElement("div", { className: "form-repeater-action" }, /* @__PURE__ */ React.createElement("button", { className: "button button--small button--grey", onClick: (e) => e.preventDefault() & addItem() }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-plus" }), t("common.add"))));
}
