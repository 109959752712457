/* eslint-disable react/prop-types */
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { styled } from '@mui/material/styles'
import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  boxSizing: 'content-box',
  padding: 3,
  fontSize: '1.125rem',
}))

export type FontAwesomeSvgIconprops = { icon: IconDefinition } & React.ComponentProps<typeof SvgIcon>

export const FontAwesomeSvgIcon = React.forwardRef<SVGSVGElement, FontAwesomeSvgIconprops>((props, ref) => {
  const { icon, ...res } = props

  const {
    icon: [width, height, , , svgPathData],
  } = icon

  return (
    <StyledSvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`} {...res}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d, i) => <path key={i} style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />)
      )}
    </StyledSvgIcon>
  )
})
