import { useApi } from '@tm/client-app/src/api'
import { GetPlansResponse } from '@tm/types/api/plans'
import { useEffect, useMemo, useState } from 'react'
import { BillingPlanContext } from './useBillingPlans'

export const BillingPlanContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [plansLoading, setPlansLoading] = useState(false)
  const [plans, setPlans] = useState<GetPlansResponse['plans']>([])
  const [error, setError] = useState<string>()
  const { appApi } = useApi()

  useEffect(() => {
    setPlansLoading(true)
    const abortController = new AbortController()
    appApi
      .get<GetPlansResponse>('/v1/plans', undefined, undefined, abortController.signal)
      .then(response => {
        setPlans(response.data.plans)
      })
      .catch((err: Error) => {
        if (!abortController.signal.aborted) setError(err.message)
      })
      .finally(() => {
        if (!abortController.signal.aborted) setPlansLoading(false)
      })
    return () => abortController.abort()
  }, [appApi])

  const value = useMemo(() => ({ plans, setPlans, plansLoading, error }), [error, plans, plansLoading])

  return <BillingPlanContext.Provider value={value}>{children}</BillingPlanContext.Provider>
}
