var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { fromJS, List } from "immutable";
import React, { useMemo } from "react";
import Select from "react-select";
const flattenOptions = (options, flatten = []) => options.reduce((flatten2, option) => {
  if (Array.isArray(option.options)) return flattenOptions(option.options, flatten2);
  return [...flatten2, option];
}, flatten);
export default function MultiSelect({ name, value: rawValue, options, onChange, simple, filterStyle }) {
  if (!List.isList(rawValue)) rawValue = List();
  const value = useMemo(() => {
    return flattenOptions(options).filter((o) => rawValue.includes(o.value));
  }, [rawValue, options]);
  return /* @__PURE__ */ React.createElement(
    Select,
    {
      value,
      isMulti: true,
      isClearable: false,
      name,
      options,
      className: "form-react-multiselect",
      onChange: (value2) => onChange({ name, value: fromJS(value2.map((o) => o.value)) }),
      styles: {
        container: (styles) => __spreadProps(__spreadValues({}, styles), { border: "none" }),
        indicatorSeparator: (styles) => ({ display: "none" }),
        menu: (styles) => __spreadProps(__spreadValues({}, styles), { zIndex: 30 }),
        control: (styles, { isFocused }) => {
          const data = __spreadProps(__spreadValues({}, styles), {
            background: "#fafafb",
            borderColor: "#a3b2c0",
            ["&:hover"]: {
              boxShadow: "none"
            }
          });
          if (isFocused) {
            data.borderColor = "#1d3a6b";
            data.offset = "none";
            data.boxShadow = "none";
          }
          if (simple) {
            data.background = "#fff";
            data.border = "none";
            data.borderRadius = "0";
            data.paddingTop = "30px";
            data.paddingBottom = "6px";
          }
          if (filterStyle) {
            data.background = "#edeef1";
            data.borderColor = "#edeef1";
            data.paddingTop = "0";
            data.paddingBottom = "0";
            data.minHeight = 30;
            data["&:hover"] = {
              boxShadow: "inset 0 0 0 1px #d0dbe6"
            };
          }
          return data;
        },
        option: (styles, state) => {
          styles["&:hover"] = {
            background: "#f4f5f7"
          };
          if (state.isFocused) styles.background = "#f4f5f7";
          return styles;
        },
        multiValue: (styles) => {
          if (filterStyle) {
            styles.backgroundColor = "#fff";
            styles.boxShadow = "0 0 1px #d0dbe6";
          }
          return styles;
        },
        valueContainer: (styles) => {
          styles.paddingTop = "4px";
          styles.paddingBottom = "4px";
          return styles;
        }
      }
    }
  );
}
