import React from "react";
import TextareaAutosize from "react-textarea-autosize";
export default function InputField(props) {
  let {
    value = "",
    type,
    name,
    onChange,
    onBlur,
    placeholder = "",
    disabled = false,
    prefix,
    suffix,
    min,
    max,
    autogrow = false,
    autoComplete
  } = props;
  let inputProps = {
    type,
    value: value || "",
    name,
    id: name,
    onChange,
    onBlur,
    placeholder,
    disabled,
    max,
    min,
    autoComplete,
    "data-testid": name
  };
  let inputType = "input";
  if (type === "textarea") inputType = autogrow ? TextareaAutosize : "textarea";
  let input = React.createElement(inputType, inputProps);
  if (prefix || suffix)
    return /* @__PURE__ */ React.createElement("div", { className: "form-input-attachments" }, prefix ? /* @__PURE__ */ React.createElement("label", { htmlFor: name, className: "form-input-attachment form-input-attachment--prefix" }, /* @__PURE__ */ React.createElement("span", { className: "ellipsis" }, prefix)) : null, input, suffix ? /* @__PURE__ */ React.createElement("label", { htmlFor: name, className: "form-input-attachment form-input-attachment--suffix" }, /* @__PURE__ */ React.createElement("span", { className: "ellipsis" }, suffix)) : null);
  return input;
}
