import { Invoice } from '@tm/server-billing/controller/stripe'
import { BillingAddress } from '@tm/server-billing/routes/patchTaxInfoRoute'
import { BillingInfo, DefaultPaymentMethod } from '@tm/types/billing'
import { List, Map, fromJS } from 'immutable'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApi } from '../../api'
import { useAuth } from '../../auth'
import { track } from '../../track'
import { BillingContext } from './useBilling'

export const BillingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { appApi } = useApi()
  const { loggedIn } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<boolean | string>(false)
  const [billing, setBilling] = useState(Map())
  const [billingAddress, setBillingAddress] = useState<BillingAddress | null>(null)
  const [cards, setCards] = useState<BillingInfo['cards']>([])
  const [vat, setVat] = useState(List())
  const [subscription, setSubscription] = useState<BillingInfo['subscription']>()
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [canUpdate, setCanUpdate] = useState(false)
  const [canCancel, setCanCancel] = useState(false)
  const [canRemoveCard, setCanRemoveCard] = useState(false)
  const [stripeTrial, setStripeTrial] = useState<any>(false)
  const [cancelInProgress, setCancelInProgress] = useState(false)
  const [currentStripeData, setCurrentStripeData] = useState<BillingInfo['currentStripeData']>(null)
  const [paymentMethod, setPaymentMethod] = useState<DefaultPaymentMethod | null>(null)

  const setupBillingData = useCallback((data: BillingInfo) => {
    setBilling(fromJS(data.billing || {}) as Map<unknown, unknown>)
    setBillingAddress(data.billingAddress)
    setCards(data.cards)
    setVat(fromJS(data.vat || []) as List<any>)
    setSubscription(data.subscription)
    setCanUpdate(data.canUpdate || false)
    setCanRemoveCard(data.canRemoveCard || false)
    setCanCancel(data.canCancel || false)
    setStripeTrial(data.stripeTrial ? fromJS(data.stripeTrial) : false)
    setCurrentStripeData(data.currentStripeData)
    setPaymentMethod(data.paymentMethod)
    setInvoices(data.invoices)
  }, [])

  const loadBillingData = useCallback(async () => {
    if (!loggedIn) return
    setLoading(true)
    return await appApi
      .get<BillingInfo>('/billing')
      .then(res => res.data)
      .then(data => {
        setupBillingData(data)
      })
      .catch((err: { message: string }) => {
        setError(err.message)
      })
      .finally(() => setLoading(false))
  }, [appApi, setupBillingData, loggedIn])

  useEffect(() => {
    const fetchData = async () => {
      await loadBillingData()
    }
    fetchData().catch(err => console.log(err))
  }, [loadBillingData])

  const deleteCardById = useCallback(
    async (card_id: string) => {
      track('BILLING_REMOVE_CREDIT_CARD')
      const response = await appApi.delete<BillingInfo | undefined>('/billing/card', { card_id })
      if (!response.data) {
        return
      }

      setupBillingData(response.data)
    },
    [appApi, setupBillingData]
  )

  const setSubscriptionData = useCallback(
    (data: BillingInfo['subscription']['data']) =>
      setSubscription({
        ...subscription!,
        data,
      }),
    [subscription]
  )

  const changeCancelStatus = useCallback(async () => {
    setCancelInProgress(true)
    const response = await appApi.post<{ subscriptionData: BillingInfo['subscription']['data'] }>('/billing/cancel')
    setSubscriptionData(response.data.subscriptionData)
    setCancelInProgress(false)
  }, [appApi, setSubscriptionData])

  const toggleSubscriptionCancelStatus = useCallback(() => {
    void changeCancelStatus()
  }, [changeCancelStatus])

  const value = useMemo(
    () => ({
      loading,
      error,
      billing,
      setupBillingData,
      cards,
      vat,
      subscription,
      invoices,
      deleteCardById,
      canUpdate,
      canRemoveCard,
      stripeTrial,
      canCancel,
      toggleSubscriptionCancelStatus,
      cancelInProgress,
      loadBillingData,
      setSubscriptionData,
      currentStripeData,
      paymentMethod,
      billingAddress,
    }),
    [
      billing,
      canCancel,
      canRemoveCard,
      canUpdate,
      cancelInProgress,
      cards,
      currentStripeData,
      deleteCardById,
      error,
      loadBillingData,
      loading,
      setSubscriptionData,
      setupBillingData,
      stripeTrial,
      subscription,
      invoices,
      toggleSubscriptionCancelStatus,
      vat,
      paymentMethod,
      billingAddress,
    ]
  )

  return <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
}
