import { UserData } from '@tm/types/common/app-api/auth'

export type HotjarWindow = Window & { hj?: (event: string, actionName: string, config?: unknown) => void }
const hotjarWindow = window as HotjarWindow

/**
 * Update Hotjar with user's information. This is done using Hotjar's Identify
 * API, which is accessible if the Hotjar's tracking code is present on the site and
 * the hj event function has been successfully added as a global function.
 * @param userData A UserData object which is used to identify the curret user
 */
export const updateHotjar = ({ contact_id, organization_id, marketingSource }: UserData) => {
  if (!hotjarWindow.hj) {
    return
  }

  hotjarWindow.hj('identify', contact_id, {
    organizationId: organization_id,
    ...marketingSource,
  })
}
