import { Alert, Box, Button } from '@mui/material'
import { useI18n } from '../i18n'

export default function AuthLoader() {
  const { t } = useI18n()
  return (
    <Box
      sx={{
        p: 3,
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
      }}>
      <Alert
        severity="error"
        action={
          <Button component="a" href="/logout" color="error" size="small" variant="outlined">
            {t('nav.logout')}
          </Button>
        }>
        {t('error.general')}
      </Alert>
    </Box>
  )
}
