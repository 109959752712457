import React from "react";
import { parseTime, units } from "../common/time";
export default function TimeField(props) {
  let { onChange, value, name, timeType = "time", t } = props;
  let { count, unit } = parseTime(value);
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--time form-field--select" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "number",
      name: "count",
      min: "0",
      value: count,
      onChange: (e) => onChange({
        name,
        value: `${e.target.value}${unit}`
      })
    }
  ), /* @__PURE__ */ React.createElement(
    "select",
    {
      value: unit,
      name: "unit",
      onChange: (e) => onChange({
        name,
        value: `${count}${e.target.value}`
      })
    },
    units.map((unit2) => {
      return /* @__PURE__ */ React.createElement("option", { value: unit2, key: unit2 }, t(`embedPopup.rules.${timeType}.${unit2}`));
    })
  ));
}
