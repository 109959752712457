import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from '../../auth'
import { getWebsocketUrl } from '../request'

export interface WebsocketContextType {
  websocket: WebSocket | null
}

export const WebsocketContext = createContext<WebsocketContextType>({} as never)

export const WebsocketContextProvider = ({ children }: { children: React.ReactNode }) => {
  // Websocket needs to be set to state, since using useRef approach didn't accurately update
  // the state of the provider and thus not updating the websocket instance
  // provided for the context value.
  const [websocket, setWebsocket] = useState<WebSocket | null>(null)
  const reconnectCountRef = useRef(0)

  const { loggedIn } = useAuth()

  /**
   * Creates a new WebSocket instance and adds a onclose event callback which
   * generates a new WebSocket instance if a disconnect occurs. After this it sets
   * the new WebSocket instance to the context's state.
   *
   * The reconnect logic is added because AWS's WebSocket APIs have a 10 minute
   * idle timeout:
   *
   * https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#apigateway-execution-service-websocket-limits-table
   */
  const reconnectWebsocket = useCallback(() => {
    if (reconnectCountRef.current >= 10) return
    const newWebsocket = new WebSocket(getWebsocketUrl())
    newWebsocket.onclose = reconnectWebsocket
    reconnectCountRef.current = reconnectCountRef.current + 1
    setWebsocket(newWebsocket)
  }, [])

  useEffect(() => {
    if (!loggedIn) return

    reconnectWebsocket()
    return () => {
      setWebsocket(ws => {
        if (ws) {
          ws.onclose = null
          ws.close()
        }
        return null
      })
    }
  }, [loggedIn, reconnectWebsocket])

  const value: WebsocketContextType = {
    websocket,
  }

  return <WebsocketContext.Provider value={value}>{children}</WebsocketContext.Provider>
}
