import { PostTrackBody } from '../../../../../server/app-api/public/routes/trackingRoutes/postTrackRoute'
import { WindowGlobals } from '../../window'

type TrackClientContext = { organizationId: string; contactId: string }

/**
 * TrackClient will send the Mixpanel-style events to backend. The events
 * will eventually be published in an SNS topic.
 */
export class TrackClient {
  static readonly apiEndpoint = '/v1/track'
  static readonly maxQueueLength = 10
  static readonly sendDelay = 5000
  private queue: PostTrackBody['events'] = []
  private timerHandle: ReturnType<typeof setTimeout>
  private context: TrackClientContext

  constructor() {
    window.addEventListener('visibilitychange', this.flush)
    window.addEventListener('pagehide', this.flush)
  }

  /** Set context, will flush existing event queue */
  public setContext = (context: TrackClientContext) => {
    this.flush()
    this.context = { ...context }
  }

  /** Flush event queue */
  private flush = () => {
    clearTimeout(this.timerHandle)

    if (this.queue.length === 0) return
    if (!this.context) {
      console.warn('TrackClientContext not set')
      return
    }

    const payload: PostTrackBody = {
      ...this.context,
      events: [...this.queue],
    }

    this.queue = []
    this.sendBeacon(payload)
  }

  /** Debounced send */
  public send = (event: PostTrackBody['events'][number]) => {
    this.queue.push(event)

    clearTimeout(this.timerHandle)

    if (this.queue.length >= TrackClient.maxQueueLength) {
      this.flush()
    } else {
      this.timerHandle = setTimeout(this.flush, TrackClient.sendDelay)
    }
  }

  /** Send to backend, use sendBeacon if the browser supports it. Fallback to fetch. */
  public sendBeacon = (body: PostTrackBody) => {
    const APP_API_ROOT = (window as unknown as WindowGlobals).APP_API_ROOT
    if (!navigator.sendBeacon) {
      void fetch(APP_API_ROOT + TrackClient.apiEndpoint, {
        method: 'post',
        body: JSON.stringify(body),
        headers: { 'Content-type': 'application/json' },
      })

      return
    }
    navigator.sendBeacon(APP_API_ROOT + TrackClient.apiEndpoint, new Blob([JSON.stringify(body)]))
  }
}
