import React, { useState } from "react";
import { Portal } from "react-portal";
import { useResponsive } from "./responsiveStore";
export function ModalButton(props) {
  let {
    title,
    info,
    description,
    icon,
    children,
    button,
    classes = [],
    buttonClasses = ["button", "button--small"],
    buttonIcon = false,
    size,
    defaultOpen = false
  } = props;
  let [open, setOpen] = useState(defaultOpen);
  let inner = typeof children === "function" ? children({ closeModal: () => setOpen(false) }) : children;
  return /* @__PURE__ */ React.createElement("div", { className: classes.join(" ") }, /* @__PURE__ */ React.createElement("button", { className: buttonClasses.join(" "), onClick: (e) => e.preventDefault() & setOpen(true) }, buttonIcon && /* @__PURE__ */ React.createElement("i", { className: `fa fa-${buttonIcon}` }), button), open && /* @__PURE__ */ React.createElement(Modal, { size }, /* @__PURE__ */ React.createElement(ModalHeader, { title, icon, description: info || description, onClose: () => setOpen(false) }), /* @__PURE__ */ React.createElement(ModalContent, null, inner)));
}
export function ModalHeader({ title = "", description = "", icon = "", titleIcon = "", onClose }) {
  const renderTitle = (titleString) => /* @__PURE__ */ React.createElement("div", { className: "modal-header-title" }, titleIcon ? /* @__PURE__ */ React.createElement("i", { className: `fa fa-${titleIcon}` }) : null, " ", titleString);
  return /* @__PURE__ */ React.createElement("div", { className: "modal-header" }, title ? renderTitle(title) : null, description ? /* @__PURE__ */ React.createElement("div", { className: "modal-header-info" }, icon ? /* @__PURE__ */ React.createElement("i", { className: `fa fa-${icon}` }) : null, description) : null, typeof onClose === "function" ? /* @__PURE__ */ React.createElement("button", { className: "modal-header-close", onClick: (e) => e.preventDefault() & onClose(), "data-testid": "close-button" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-times" })) : null);
}
export function ModalContent(props) {
  const { children, className } = props;
  const classNames = ["modal-content"];
  if (className) classNames.push(className);
  return /* @__PURE__ */ React.createElement("div", { className: classNames.join(" ") }, children);
}
export function Modal(props) {
  let { breakpointClasses } = useResponsive();
  let { children, size = "medium", id = "default", externalClasses = [] } = props;
  let classes = [...externalClasses, "modal", "modal--responsive", `modal--${size}`, `modal--${id}`];
  return /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement("div", { className: breakpointClasses.map((s) => `breakpoint-${s}`).join(" ") }, /* @__PURE__ */ React.createElement("div", { className: classes.join(" ") }, /* @__PURE__ */ React.createElement("div", { className: "modal-inner" }, children))));
}
