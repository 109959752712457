import { sanitizeWysiwygHtml } from "@tm/shared-lib/src/sanitize";
import React from "react";
import { Field, Form, Submit } from "../form";
export default function TermOverlay(props) {
  let { acceptTermAction, acceptTermLoading, acceptTerm, t } = props;
  let { hasAcceptedTerms = false, term } = acceptTermAction;
  return /* @__PURE__ */ React.createElement("div", { className: "term-overlay" }, /* @__PURE__ */ React.createElement("div", { className: "term-overlay-inner" }, /* @__PURE__ */ React.createElement("div", { className: "term-overlay-header" }, /* @__PURE__ */ React.createElement("h2", null, hasAcceptedTerms ? t("term.updateTitle") : t("term.acceptTitle")), /* @__PURE__ */ React.createElement("p", null, t("term.description"))), /* @__PURE__ */ React.createElement("div", { className: "term-overlay-content" }, /* @__PURE__ */ React.createElement("div", { className: "styled-content", dangerouslySetInnerHTML: { __html: sanitizeWysiwygHtml(term.content) } })), /* @__PURE__ */ React.createElement("div", { className: "term-overlay-action" }, acceptTermLoading ? /* @__PURE__ */ React.createElement("i", { className: "fa fa-spin fa-spinner" }) : /* @__PURE__ */ React.createElement(Form, { onSubmit: () => acceptTerm(term.id), validation: { accept: ["required"] } }, /* @__PURE__ */ React.createElement(
    Field,
    {
      type: "checkbox",
      inputLabel: t("term.acceptText"),
      link: {
        url: "https://www.trustmary.com/privacy-policy/",
        text: "Privacy Policy"
      },
      name: "accept"
    }
  ), /* @__PURE__ */ React.createElement(Submit, { className: "button", text: t("term.acceptButton") })))));
}
