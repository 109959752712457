import * as Sentry from '@sentry/react'
import uncaught from 'uncaught'

const caughtErrors = new Set<Error>([])
let errorReceiver: (error: Error) => void | undefined

/**
 * Triggers the ErrorBoundary error manually, the component needs to setup the receiver to the instance
 */
export const setupErrorReceiver = (receiver: (error: Error) => void) => (errorReceiver = receiver)

export const catchError = (err: Error) => {
  // Prevent the same error being reported twice
  if (caughtErrors.has(err)) return
  caughtErrors.add(err)

  // Log error
  Sentry.captureException(err)

  // Trigger ErrorBoundary to display the error

  // TODO: Remove comment when there are no harmless unhandled errors
  // if (errorReceiver) errorReceiver(err)
}

uncaught.start()
uncaught.addListener(catchError)
