import { TrackEvent } from '@tm/shared-lib/src/track'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { TrackClient } from './common/trackClient'
import { WindowGlobals } from './window'

export const trackClient = new TrackClient()

export const dataLayerPush = (event: TrackEvent, options?: Record<string, unknown>) => {
  const globalWindow = window as unknown as WindowGlobals
  // Send event to Google Tag Manager
  globalWindow.dataLayer = globalWindow.dataLayer || []
  globalWindow.dataLayer.push({
    event: `trustmary.${event}`,
    ...options,
  })
}

// Get Mixpanel project token from "env variables", put in there by Webpack.
const token = process.env.MIXPANEL_TOKEN || ''
mixpanel.init(token, { api_host: process.env.MIXPANEL_PROXY_URL })

/**
 * Track UI iniated events. Sends them to Mixpanel and GTM
 */
export const track = (event: TrackEvent, options?: Record<string, unknown>) => {
  // Send event to Mixpanel
  mixpanel.track(event, options)

  dataLayerPush(event, options)

  // Send event to be sent to the SNS topic
  trackClient.send({ event, options })
}

/**
 * useTrack hook
 * Run track when requirements change
 */

export const useTrack = (event: TrackEvent, requirements: unknown[], options?: Record<string, unknown>) => {
  useEffect(() => {
    track(event, options)
  }, requirements)
}
