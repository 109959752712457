var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import React, { Component } from "react";
import FormStore from "./formStore";
import Field from "./lazyField";
import Submit from "./submit";
class FormWizard extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "getOpenSteps", () => {
      let { formState, steps } = this.props;
      let { values } = formState;
      return steps.filter((step) => typeof step.isOpen === "function" ? step.isOpen.call(null, values) : true);
    });
    let { steps } = props;
    let [firstStep = {}] = steps;
    this.state = {
      activeStep: firstStep.id || false
    };
  }
  render() {
    let { title, lang } = this.props;
    let openSteps = this.getOpenSteps();
    return /* @__PURE__ */ React.createElement("div", { className: "form-wizard" }, title ? /* @__PURE__ */ React.createElement("div", { className: "form-wizard-title" }, title) : null, /* @__PURE__ */ React.createElement("div", { className: "form-wizard-steps" }, openSteps.map((step) => {
      let { id, fields = [], full = false, container = false, submit = false } = step;
      let classes = ["form-wizard-step"];
      if (container) classes.push("form-wizard-step--container");
      if (full) classes.push("form-wizard-step--full");
      if (submit)
        return /* @__PURE__ */ React.createElement("div", { className: "form-wizard-submit", key: id }, /* @__PURE__ */ React.createElement("div", { className: "form-wizard-submit-inner" }, /* @__PURE__ */ React.createElement(Submit, { text: submit, icon: "plus" })));
      return /* @__PURE__ */ React.createElement("div", { className: classes.join(" "), key: id }, /* @__PURE__ */ React.createElement("div", { className: "form-wizard-step-fields" }, fields.map((field) => /* @__PURE__ */ React.createElement(Field, __spreadProps(__spreadValues({}, field), { lang, key: field.name })))));
    })));
  }
}
export default (props) => /* @__PURE__ */ React.createElement(FormStore, null, (formState) => /* @__PURE__ */ React.createElement(FormWizard, __spreadValues(__spreadValues({}, props), formState)));
