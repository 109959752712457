import { Map } from 'immutable'
import { createContext } from 'react'
import { Translator } from '../i18n'

export interface FormContextType<T = unknown> {
  typedValues: T
  formState: { values: Map<string, unknown>; errors: Record<string, string[]>; errorsMap: Map<string, unknown> }
  formLoading: boolean
  formUpdate(events: any, callback?: () => void): void
  formSubmit(e?: any, fieldsToSave?: string[]): void
  formFileUpdate(events: any, callback?: boolean): void
  formFileRemove(events: any, callback?: boolean): void
  formInit(values: T): void
  formSetLoading(state: boolean): void
  formSetSubmitListener(listener: any): void
  t: Translator
}

export default createContext<FormContextType>({} as never)
