import React from "react";
export default function IconSelect(props) {
  let { name, value, onChange, options } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--iconselect form-iconselect" }, options.map((option) => {
    let { label, value: optionValue, description, icon } = option;
    let isSelected = optionValue === value;
    let classes = ["form-iconselect-item"];
    if (isSelected) classes.push("form-iconselect-item--selected");
    return /* @__PURE__ */ React.createElement("div", { className: "form-iconselect-item-wrapper", key: optionValue }, /* @__PURE__ */ React.createElement(
      "button",
      {
        className: classes.join(" "),
        onClick: (e) => e.preventDefault() & onChange({ name, value: optionValue })
      },
      /* @__PURE__ */ React.createElement("div", { className: "form-iconselect-item-inner" }, /* @__PURE__ */ React.createElement("div", { className: "form-iconselect-item-icon" }, /* @__PURE__ */ React.createElement("i", { className: `fa fa-${icon}` })), /* @__PURE__ */ React.createElement("div", { className: "form-iconselect-item-label" }, label), /* @__PURE__ */ React.createElement("div", { className: "form-iconselect-item-description" }, description))
    ));
  }));
}
