import axios, { AxiosError, AxiosResponse, Method } from 'axios'
import jwtDecode from 'jwt-decode'

export const LOCAL_ORGANIZATION_KEY = 'localOrganization'

interface IToken {
  current: string | false
  expire: number
}

const apiToken: IToken = {
  current: false,
  expire: 0,
}

export const getWebsocketUrl = () => `${process.env.WEBSOCKET_API_URL || ''}?idToken=${apiToken.current || ''}`

export const setApiToken = (token: string) => {
  if (!token) return
  try {
    const data: { exp: number } = jwtDecode(token)
    apiToken.expire = data.exp * 1000
    apiToken.current = token
  } catch (err) {
    console.log(err)
  }
}

interface UpdateTokenHeaders {
  'update-token'?: string
}

function refreshToken() {
  return axios({
    method: 'POST',
    url: '/api/auth',
    headers: {
      Authorization: `Bearer ${apiToken.current || ''}`,
    },
  }).then(res => {
    const headers = res.headers as UpdateTokenHeaders
    if (headers['update-token']) setApiToken(headers['update-token'])
    return res
  })
}

export interface ApiError extends AxiosError {
  _apierror?: boolean
}

export function request<Response, Body = unknown>(
  method: Method,
  url: string,
  data?: Body,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logoutOn403 = true,
  signal?: AbortSignal
): Promise<AxiosResponse<Response>> {
  const headers: { [name: string]: string } = {}

  if (apiToken.current) headers.Authorization = `Bearer ${apiToken.current}`

  const expiredToken = !!apiToken.expire && new Date().getTime() > apiToken.expire

  const prefetch = expiredToken ? refreshToken() : Promise.resolve()

  return prefetch
    .then(() => {
      // Add the organization that is shown in the browser as a header
      // to make sure that the request is handled according to the correct
      // organization.
      const localOrgKey = sessionStorage.getItem(LOCAL_ORGANIZATION_KEY)
      if (localOrgKey) headers['X-User-Organization'] = localOrgKey

      const params = {
        method,
        url,
        headers,
        data: data || {},
        signal,
      }
      return axios(params)
    })
    .then(res => {
      const headers = res.headers as UpdateTokenHeaders
      if (headers['update-token']) setApiToken(headers['update-token'])
      return res as AxiosResponse<Response>
    })
    .catch((err: ApiError) => {
      const requestObj = err.request as XMLHttpRequest | undefined
      const response = requestObj?.response as string | undefined

      //Request canceled most probably using abort signal
      if (err.code === 'ERR_CANCELED') return requestObj?.response as AxiosResponse<Response>

      const isAccessDeniedError = (response || '').indexOf('access_denied') > -1
      if (isAccessDeniedError && logoutOn403 && requestObj && requestObj.status === 403) {
        window.location.href = '/logout'
        return requestObj.response as AxiosResponse<Response>
      }

      console.log(err)

      err._apierror = true
      throw err
    })
}
