import { UserData } from '@tm/types/common/app-api/auth'

/**
 * Tawk let plan to have custom key, but created random id for the rest and couldn't get it work so we use random ids
 */
export enum TAWK_ATTRIBUTE {
  organization_name = 'kjobxpomns',
  organization_id = 'lqpdkjd2x1',
  plan = 'plan',
}

type TawkVisitor = {
  email: string
  name: string | null
  hash: string
} & Record<TAWK_ATTRIBUTE, string | null>

export interface TawkWindow extends Window {
  Tawk_API?: {
    onLoad?: () => void
    setAttributes?: (attr: TawkVisitor, errorFn: (error: Error) => void) => void
    logout: () => void
  }
  Tawk_LoadStart?: Date
}

/**
 * It seems that Tawk will override the attributes if we call it too soon, so we need to wait a bit
 */
const tawkSetAttributes = (attributes: TawkVisitor) => {
  const tawkWin = window as unknown as TawkWindow
  setTimeout(() => {
    if (typeof tawkWin.Tawk_API?.setAttributes === 'function')
      tawkWin.Tawk_API?.setAttributes(attributes, err => console.log(err))
  }, 1000)
}

export const updateTawk = (userData: UserData) => {
  try {
    const tawkWin = window as unknown as TawkWindow

    const { email, name, tawkHash } = userData

    if (!tawkHash || !tawkWin.Tawk_API) return

    const {
      name: organization_name,
      id: organization_id,
      appSubscriptionInfo: { plan_id },
    } = userData.organization

    const plans = userData.organization.appSubscriptionInfo.subscription.data.plans || []
    const plan = plans.length ? plans.map(plan => plan.planId).join(', ') : plan_id

    const attributes: TawkVisitor = {
      name: name || email,
      email,
      hash: tawkHash,
      [TAWK_ATTRIBUTE.organization_name]: organization_name,
      [TAWK_ATTRIBUTE.organization_id]: organization_id,
      [TAWK_ATTRIBUTE.plan]: plan,
    }

    if (typeof tawkWin.Tawk_API?.setAttributes === 'function') {
      tawkSetAttributes(attributes)
    } else {
      tawkWin.Tawk_API.onLoad = () => {
        tawkSetAttributes(attributes)
      }
    }

    tawkWin.Tawk_LoadStart = new Date()
  } catch (error) {
    console.log('Tawk error updateTawk')
    console.log(error)
  }
}

export const resetTawk = () => {
  try {
    const tawkWin = window as unknown as TawkWindow
    tawkWin.Tawk_API?.logout()
  } catch (error) {
    console.log('Tawk error resetTawk')
    console.log(error)
  }
}
