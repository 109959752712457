import moment from "moment";
import React, { useCallback, useState } from "react";
import Datepicker from "react-datetime";
import "./datetime.sass";
function DatepickerInputContent(props) {
  const { inputProps, date, placeholder } = props;
  if (date) {
    const hoursOffset = date.utcOffset() / 60;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, inputProps.value, " (UTC ", hoursOffset < 0 ? hoursOffset : "+" + hoursOffset, ")");
  } else {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, placeholder);
  }
}
function DatepickerInput(props) {
  const { inputProps, openCalendar, closeCalendar, date, placeholder } = props;
  const [open, setOpen] = useState(false);
  const onInputClick = useCallback(
    (e) => {
      e.preventDefault();
      open ? closeCalendar() : openCalendar();
      setOpen(!open);
    },
    [open]
  );
  return /* @__PURE__ */ React.createElement("button", { className: "button button--small button--grey", onClick: onInputClick }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-calendar" }), /* @__PURE__ */ React.createElement(DatepickerInputContent, { inputProps, date, placeholder }));
}
export default function DatetimeField(props) {
  let { onChange, value, name, minDate, maxDate, placeholder } = props;
  let date;
  if (value) date = moment(value);
  else if (!placeholder) date = moment();
  return /* @__PURE__ */ React.createElement("div", { className: "form-field form-field--datetime" }, /* @__PURE__ */ React.createElement(
    Datepicker,
    {
      className: "datetime-datepicker",
      timeFormat: true,
      input: true,
      renderInput: (inputProps, openCalendar, closeCalendar) => /* @__PURE__ */ React.createElement(
        DatepickerInput,
        {
          inputProps,
          openCalendar,
          closeCalendar,
          date,
          placeholder
        }
      ),
      value: date,
      isValidDate: (currentDate, selectedDate) => {
        const isAfterMinDate = !minDate || currentDate.isSameOrAfter(moment(minDate).startOf("day"));
        const isBeforeMaxDate = !maxDate || currentDate.isSameOrBefore(moment(maxDate).endOf("day"));
        return isAfterMinDate && isBeforeMaxDate;
      },
      onChange: (e) => {
        if (typeof e === "string") {
        } else {
          onChange({
            name,
            value: e.format()
          });
        }
      }
    }
  ));
}
