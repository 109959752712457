import { UserData } from '@tm/types/common/app-api/auth'

type CelloParams = {
  productId: string
  token: string
  language: string
  hideDefaultLauncher?: boolean
  productUserDetails: {
    firstName?: string
    lastName?: string
    fullName?: string
    email: string
  }
}

type Cello = {
  boot: (params: CelloParams) => void
}

type CelloCallback = (cello: Cello) => void

type CelloCommand = 'shutdown' | 'show' | 'hide' | 'open'

export interface CelloWindow extends Window {
  cello?: {
    cmd: {
      push: (cb: CelloCallback) => void
      shift: (cb: CelloCallback) => void
    }
  }
  Cello?: (cmd: CelloCommand) => void
}

export const celloWindow = window as unknown as CelloWindow

export const celloCommand = (cmd: CelloCommand) => {
  if (!celloWindow.Cello) return
  celloWindow.Cello(cmd)
}

const getCelloName = (fullName: string | null) => {
  if (!fullName) return {}

  const [firstName, ...lastName] = fullName.split(' ')

  return {
    firstName,
    lastName: lastName.join(' '),
    fullName,
  }
}

export const updateCello = (userData: UserData) => {
  try {
    const { email, name, celloParams, lang } = userData

    if (!celloParams || !celloWindow.cello) return

    celloWindow.cello.cmd.push(cello => {
      cello.boot({
        productId: celloParams.productId,
        token: celloParams.token,
        language: lang,
        hideDefaultLauncher: true,
        productUserDetails: {
          ...getCelloName(name),
          email,
        },
      })
    })
  } catch (error) {
    console.log('Cello error')
    console.log(error)
  }
}

export const resetCello = () => {
  celloCommand('shutdown')
}
