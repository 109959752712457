import { List, Map } from "immutable";
import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import "./conditions.sass";
const operators = ["is", "isnot", "greaterthan", "lessthan", "istrue", "isfalse", "isnull", "notnull"];
const npsFieldOperators = ["is", "isnot", "greaterthan", "lessthan"];
const disableOperatorField = ["istrue", "isfalse", "isnull", "notnull"];
const npsValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function OperatorField({ operator, test, name, onChange, field, t }) {
  if (!field) return null;
  let { type, options = [] } = field;
  if (disableOperatorField.includes(operator) || !type) return null;
  if (type === "nps" && npsFieldOperators.includes(operator)) {
    return /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-test conditions-item-input-test--nps" }, /* @__PURE__ */ React.createElement("select", { name, onChange: (e) => onChange({ name, value: parseInt(e.target.value) }), value: test.toString() }, !test ? /* @__PURE__ */ React.createElement("option", { value: "" }, t("conditions.field.chooseValue")) : null, npsValues.map((nps) => /* @__PURE__ */ React.createElement("option", { value: nps, key: nps }, nps))));
  }
  if (["radios", "checkboxes", "dropdown", "multidropdown"].includes(type)) {
    return /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-test conditions-item-input-test--select" }, /* @__PURE__ */ React.createElement("select", { name, onChange, value: test }, !test ? /* @__PURE__ */ React.createElement("option", { value: "" }, t("conditions.field.chooseValue")) : null, options.map(({ value, label }) => {
      if (Array.isArray(label)) label = label.map((i) => t(i)).join(" ");
      return /* @__PURE__ */ React.createElement("option", { value, key: value }, label);
    })));
  }
  return /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-test conditions-item-input-test--text" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "text",
      name,
      onChange,
      value: test || "",
      placeholder: t("conditions.field.inputPlaceholder")
    }
  ));
}
const Handle = SortableHandle((props) => /* @__PURE__ */ React.createElement("div", { className: "conditions-handle" }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-bars" })));
const ConditionItem = SortableElement((props) => {
  let { condition, onChange, fields, onRemove, itemIndex: index, t } = props;
  let fieldValue = condition.get("field", "");
  let field = fields.find((f) => f.value === fieldValue);
  return /* @__PURE__ */ React.createElement("div", { className: "conditions-item" }, /* @__PURE__ */ React.createElement("div", { className: "conditions-item-handle" }, /* @__PURE__ */ React.createElement(Handle, null)), /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input" }, /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-row" }, index ? /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-join" }, /* @__PURE__ */ React.createElement("select", { value: condition.get("join"), name: `${index}.join`, onChange }, /* @__PURE__ */ React.createElement("option", { value: "and" }, t("conditions.field.and")), /* @__PURE__ */ React.createElement("option", { value: "or" }, t("conditions.field.or")))) : null, /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-field" }, /* @__PURE__ */ React.createElement("select", { value: fieldValue, name: `${index}.field`, onChange }, !fieldValue ? /* @__PURE__ */ React.createElement("option", { value: "" }, t("conditions.field.chooseField")) : null, fields.map(({ label, value, level, title }, key) => {
    level = level === 2 ? "-- " : level === 1 ? "- " : "";
    if (Array.isArray(label)) label = label.map((i) => t(i)).join(" ");
    if (Array.isArray(title)) title = title.map((i) => t(i)).join(" ");
    if (title)
      return /* @__PURE__ */ React.createElement("option", { value: "", disabled: true, key }, level, title);
    return /* @__PURE__ */ React.createElement("option", { value, key }, level, label);
  })))), /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-row" }, /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-operator" }, /* @__PURE__ */ React.createElement("select", { value: condition.get("operator"), name: `${index}.operator`, onChange }, operators.map((operator) => /* @__PURE__ */ React.createElement("option", { value: operator, key: operator }, t(`conditions.operators.${operator}`)))))), /* @__PURE__ */ React.createElement("div", { className: "conditions-item-input-row" }, /* @__PURE__ */ React.createElement(
    OperatorField,
    {
      operator: condition.get("operator"),
      test: condition.get("test", ""),
      name: `${index}.test`,
      field,
      t,
      onChange
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "conditions-item-remove" }, /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & onRemove(index) }, /* @__PURE__ */ React.createElement("i", { className: "fa fa-trash" }))));
});
const ConditionsList = SortableContainer(({ conditions, onChange, onRemove, fields, t }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "conditions-list" }, conditions.map((condition, index) => /* @__PURE__ */ React.createElement(
    ConditionItem,
    {
      key: index,
      index,
      itemIndex: index,
      condition,
      onChange,
      onRemove,
      fields,
      t
    }
  )));
});
export default function ConditionsField(props) {
  let { value, name, fields = [], t, disabled } = props;
  if (!List.isList(value)) value = List();
  let parentRef = useRef();
  const handleConditionChange = (e) => {
    let { name: inputName, value: inputValue } = e.target || e;
    let { value: value2, name: name2, onChange } = props;
    if (!List.isList(value2)) value2 = List();
    onChange({
      name: name2,
      value: value2.setIn(inputName.split("."), inputValue)
    });
  };
  const handleCreate = (join) => {
    let { value: value2, name: name2, onChange } = props;
    if (!List.isList(value2)) value2 = List();
    onChange({ name: name2, value: value2.push(Map().set("join", join).set("operator", "is")) });
  };
  const removeCondition = (index) => {
    let { value: value2, name: name2, onChange } = props;
    onChange({
      name: name2,
      value: value2.remove(index)
    });
  };
  const handleReorder = ({ oldIndex, newIndex }) => {
    let { value: value2, name: name2, onChange } = props;
    let source = value2.get(oldIndex);
    value2 = value2.setIn([oldIndex, "_delete"], true).insert(oldIndex > newIndex ? newIndex : newIndex + 1, source).filter((s) => s.get("_delete", false) === false);
    onChange({ name: name2, value: value2 });
  };
  return /* @__PURE__ */ React.createElement("div", { className: `form-field form-field--conditions ${disabled ? "form-field--disabled" : ""}`, ref: parentRef }, value.size ? /* @__PURE__ */ React.createElement("div", { className: "conditions conditions--edit" }, /* @__PURE__ */ React.createElement(
    ConditionsList,
    {
      conditions: value,
      onChange: handleConditionChange,
      onRemove: removeCondition,
      onSortEnd: handleReorder,
      name,
      fields,
      useDragHandle: true,
      t
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "conditions-create" }, /* @__PURE__ */ React.createElement("div", { className: "conditions-create-button" }, /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & handleCreate("and") }, /* @__PURE__ */ React.createElement("span", null, "+"), " ", t("conditions.field.and")), /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & handleCreate("or") }, /* @__PURE__ */ React.createElement("span", null, "+"), " ", t("conditions.field.or"))))) : /* @__PURE__ */ React.createElement("div", { className: "conditions condition--empty" }, /* @__PURE__ */ React.createElement("div", { className: "conditions-create" }, /* @__PURE__ */ React.createElement("div", { className: "conditions-create-button" }, /* @__PURE__ */ React.createElement("button", { onClick: (e) => e.preventDefault() & handleCreate("and") }, /* @__PURE__ */ React.createElement("span", null, "+"), " ", t("conditions.field.createFirst"))))), disabled && /* @__PURE__ */ React.createElement("div", { className: "form-field-overlay" }));
}
